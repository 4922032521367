<template>
  <section class="user-page entity-edit-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="page-title" v-if="user">
        <h1>{{ user.lastName }} {{ user.firstName }}</h1>
      </div>

      <el-tabs v-model="tab" type="border-card">
        <el-tab-pane name="info">
          <span slot="label">
            <i class="el-icon-notebook-2" /> {{ $t('user.profile') }}
          </span>

          <UserForm
            class="form"
            :user="user"
            v-loading="isVLoading"
            @submit="submit"
          />
        </el-tab-pane>

        <el-tab-pane v-if="user.id" name="history" lazy>
          <span slot="label">
            <i class="el-icon-time" />
            {{ $t('user.testingHistory') }}
          </span>

          <TestingHistory :user="user" @reload-data="load" />
        </el-tab-pane>
      </el-tabs>
    </template>
  </section>
</template>

<script>
import { notify, getDefault } from '@/services'
import UserForm from './components/UserForm'
import TestingHistory from './components/TestingHistory'

export default {
  name: 'UserPage',

  components: {
    UserForm,
    TestingHistory,
  },

  data() {
    return {
      isLoading: true,
      isVLoading: false,
      user: null,
    }
  },

  computed: {
    tab: {
      get() {
        return this.$route.query.tab || 'info'
      },
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
    },
  },

  created() {
    return Promise.all([this.loadDependencies(), this.load()])
  },

  methods: {
    loadDependencies() {
      return this.$store.dispatch(
        'dependency/fetchForFilter',
        ['subdivision', 'position', 'currentEvent', 'testingAim'],
      )
    },

    async load() {
      this.isLoading = true
      const { id } = this.$route.params

      try {
        this.user = id
          ? await this.$store.dispatch('user/get', { id })
          : getDefault('user')
      } catch (e) {
        console.error(e)
        this.user = getDefault('user')
        return this.$router.push({ name: 'users' })
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isVLoading = true

      try {
        // TODO: implements update only changed fields
        this.user = await this.$store.dispatch(
          `user/${formData.id ? 'update' : 'create'}`,
          formData,
        )

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            `messages.user${formData.id ? 'Updated' : 'Created'}Success`,
            { name: `${this.user.lastName} ${this.user.firstName}` },
          ),
        }, true)

        return this.$router.push({ name: 'users' })
      } catch (e) {
        console.error(e)
      } finally {
        this.isVLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/components/entity-edit-page.scss";
</style>
