import { Notification } from 'element-ui'

const TYPE = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

export const notify = (
  {
    title = '',
    message = '',
    type = 'error',
    duration = 5500,
    offset = 42,
  },
  isHtml = false,
) => {
  if (!TYPE[type]) throw new Error('Wrong notify type')

  return Notification[TYPE[type]]({
    title,
    message,
    duration,
    offset,
    position: 'bottom-right',
    dangerouslyUseHTMLString: isHtml,
  })
}

export const errNotify = (e, { type, message } = {}) => notify({
  title: `Error: ${e.response?.status || ''}`,
  message: message || e.response?.data?.message || e,
  type,
})
