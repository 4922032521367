<template>
  <div
    class="admin-info"
    @click.stop
    @mouseenter="inFocus = true"
    @mouseleave="hideCard"
  >
    <div class="ava-btn" @click.stop="isShowCard = !isShowCard">
      <div class="mr-1 admin-name">
        {{ info.firstName }} {{ info.lastName }}
      </div>

      <BlockSize size="md" :src="img">
        <i v-if="!img" class="el-icon-admin empty-icon" />
      </BlockSize>
    </div>

    <el-card v-if="isShowCard" class="relative">
      <div class="close-icon" >
        <el-link :underline="false" @click="isShowCard = false">
          <i class="el-icon-close" />
        </el-link>
      </div>

      <el-row class="mb-1">
        <BlockSize class="mr-2" size="lg" :src="img" />

        <div>
          <div class="name">
            {{ info.firstName }} {{ info.lastName }}
          </div>

          <div class="info">
            <div>
              <el-link icon="el-icon-postcard" type="primary">
                {{ info.email }}
              </el-link>
            </div>
            <div>{{ get(info, 'subdivision.name', '') }}</div>
            <div>{{ get(info, 'position.name', '') }}</div>
          </div>
        </div>
      </el-row>

      <el-row justify="end" align="middle">
        <div class="mr-1">
          <el-button icon="el-icon-lock" @click="logout">
            {{$t('routes.logout') }}
          </el-button>
        </div>

        <LocaleSwitcher class="mr-1" />

        <el-button icon="el-icon-setting" @click="edit" />
      </el-row>
    </el-card>

    <el-dialog
      width="600px"
      :title="$t('user.profile')"
      :visible.sync="isShowEdit"
    >
      <AdminEditForm
        v-if="isShowEdit"
        @close="isShowEdit = false"
        @updated="isShowEdit = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import BlockSize from '@/modules/core/components/BlockSize'
import LocaleSwitcher from '@/modules/core/components/LocaleSwitcher'
import { env, get } from '@/services'

export default {
  name: 'AdminInfo',

  components: {
    BlockSize,
    LocaleSwitcher,
    /* eslint-disable max-len */
    AdminEditForm: () => import(/* webpackChunkName: "AdminEditForm" */ './AdminEditForm'),
    /* eslint-enable max-len */
  },

  data() {
    return {
      isShowCard: false,
      isShowEdit: false,
      inFocus: false,
    }
  },

  computed: {
    img() {
      const img = get(this, 'info.corporation.image')
      return img ? `${env.FS_BASE_URL}/${img}` : null
    },

    info() {
      return this.$store.getters['auth/admin']
    },
  },

  methods: {
    get,

    async logout() {
      const isAgree = await this.$confirm(
        this.$t('auth.logoutConfirm'),
        { type: 'warning' },
      )

      if (!isAgree) {
        return null
      }

      try {
        await this.$store.dispatch('auth/logout')
        await this.$router.push({ name: 'login' })
        return await this.$store.dispatch('dependency/clearAll')
      } catch (e) {
        console.error(e)
      }
    },

    hideCard() {
      this.inFocus = false

      setTimeout(() => {
        if (!this.inFocus) this.isShowCard = false
      }, 1500)
    },

    edit() {
      this.hideCard()
      this.isShowEdit = true
    },
  },
}
</script>

<style scoped lang="scss">
.admin-info {
  display: flex;
  height: 60px;
  position: relative;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;

    .el-link {
      cursor: pointer;
      display: block;
      padding: 2px;
      color: $grey-500;
    }
  }

  .ava-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin: 2px;
    cursor: pointer;

    &:hover {
      background-color: $blue-50;
    }

    .empty-icon {
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
      font-size: 1.8rem;
    }
  }

  .el-card {
    position: absolute;
    min-width: 400px;
    top: 4px;
    right: 0;
    z-index: 999;

    .name {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 10px;
      color: $--color-text-primary;
      word-break: normal;
    }

    .info * {
      font-size: .8rem;
    }

    //.locale-switcher {
    //  border: 1px solid $grey-300;
    //  border-radius: 3px;
    //  padding-top: 2px;
    //  padding-right: 2px;
    //
    //  ::v-deep .el-input {
    //    margin-top: 2px;
    //  }
    //}
  }

  @media only screen and (max-width: $--md) {
    .admin-name {
      display: none;
    }
  }
}
</style>
