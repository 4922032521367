import { GET } from '@/plugins/http'

export default {
  state: () => ({
    currentEvents: [],
  }),

  mutations: {
    setCurrentEvents: (state, payload) => { state.currentEvents = payload },
  },

  actions: {
    async fetchForFilter({ commit, getters }) {
      if (getters.currentEvents.length) {
        return getters.currentEvents
      }

      const { data } = await GET('/current-events')

      commit('setCurrentEvents', data)

      return data
    },
  },

  getters: {
    currentEvents: state => state.currentEvents,
  },
}
