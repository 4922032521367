<template>
  <el-menu
    router
    class="nav-menu"
    mode="horizontal"
    :default-active="$route.name"
    @select="select"
  >
    <el-row justify="space-between" align="middle">
      <el-row align="middle">
        <el-menu-item
          v-for="item in menuItems"
          :key="item.name"
          :index="item.name"
          :route="{ name: item.name }"
        >
          <i :class="item.icon" />
          <span v-if="!maxmq('md')">{{ $t(`routes.${item.name}`) }}</span>
        </el-menu-item>

        <el-submenu
          index="administration"
          v-if="rights.includes('tab_administration') && isAuth"
        >
          <template slot="title">
            <i class="el-icon-s-tools" />
            <span v-if="!maxmq('md')">{{ $t('routes.administration') }}</span>
          </template>

          <el-menu-item
            v-for="item in administrationItems"
            :key="item.name"
            :index="item.name"
            :route="{ name: item.name }"
          >
            <i :class="item.icon" />
            <span>{{ $t(`routes.${item.name}`) }}</span>
          </el-menu-item>
        </el-submenu>
      </el-row>

      <el-row align="middle">
        <AdminInfo v-if="isAuth" />

        <template v-else>
          <el-menu-item index="login" :route="{ name: 'login' }">
            <i class="el-icon-lock" />
            <span v-if="!maxmq('md')">{{ $t('routes.login') }}</span>
          </el-menu-item>

          <LocaleSwitcher />
        </template>
      </el-row>
    </el-row>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdminInfo } from '@/modules/auth'
import LocaleSwitcher from './LocaleSwitcher'

export default {
  name: 'NavMenu',

  components: {
    AdminInfo,
    LocaleSwitcher,
  },

  computed: {
    ...mapGetters({
      isAuth: 'auth/isAuth',
      rights: 'dependency/rights',
    }),

    menuItems() {
      return this.isAuth
        ? [
          { icon: 'el-icon-user', name: 'users' },
          // { icon: 'el-icon-s-data', name: 'analytics' },
          { icon: 'el-icon-timer', name: 'tests' },
        ]
        : [
          // { icon: 'el-icon-s-opportunity', name: 'solutions' },
          { icon: 'el-icon-timer', name: 'tests' },
          // { icon: 'el-icon-info', name: 'about' },
        ]
    },

    administrationItems() {
      return this.isAuth
        ? [
          { icon: 'el-icon-user-solid', name: 'sub_admins' },
          { icon: 'el-icon-office-building', name: 'subdivisions' },
        ]
        : []
    },
  },

  methods: {
    select(name) {
      if (name && !name.startsWith('btn')) {
        this.$router.push({ name })
      }
    },
  },
}
</script>

<style scoped>
.locale-switcher {
  padding: 7px 10px 0;
}
</style>
