import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import isEqual from 'lodash/isEqual'
import env from './envConstants'
import { CurrentEvent } from './library'

const pluck = (arr, prop) => (
  Array.isArray(arr) ? arr.map(a => a[prop]) : []
)
const head = list => (
  (Array.isArray(list) || typeof list === 'string') ? list[0] : undefined
)

const getIndex = page => (page - 1) * env.PAGINATION_SIZE + 1

const indexToPage = index => Math
  .round(index / (env.PAGINATION_SIZE + 1) + 1)

const canViewAssignments = status => {
  const { ASSIGNED, NOT_RECOMMEND, RECOMMEND } = CurrentEvent
  return [ASSIGNED, NOT_RECOMMEND, RECOMMEND].includes(status)
}

const canAssignTest = status => {
  const { NOT_ASSIGNED, NOT_RECOMMEND, RECOMMEND } = CurrentEvent
  return [NOT_ASSIGNED, NOT_RECOMMEND, RECOMMEND].includes(status)
}

const canReset = status => (status === CurrentEvent.ASSIGNED)

export {
  cloneDeep,
  debounce,
  get,
  chunk,
  isEqual,
  pluck,
  head,
  getIndex,
  indexToPage,
  canViewAssignments,
  canAssignTest,
  canReset,
}
