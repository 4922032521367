<template>
  <div class="assign-form">
    <TheLoader v-if="isLoading" />

    <el-form
      v-else
      ref="form"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="testingAimId" :label="$t('form.testingAim.label')">
        <el-select filterable v-model="formData.testingAimId">
          <el-option
            v-for="ta in testingAims"
            :key="ta.id"
            :label="ta.name"
            :value="ta.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="dueDate" :label="$t('form.dueDate.label')">
        <el-date-picker
          type="date"
          format="yyyy-MM-dd HH:mm"
          :picker-options="pickerOptions"
          v-model="formData.dueDate"
          @change="datePickerUpdateTime"
        />
      </el-form-item>

      <el-form-item :label="$t('form.selectTests.label')">
        <TestsTable
          class="tests-table"
          :value="tests"
          :selected-tests="formData.selectedTests"
          @selection-change="formData.selectedTests = $event"
        />
      </el-form-item>

      <el-row class="actions" justify="end">
        <el-button @click="$emit('close')">
          {{ $t('buttons.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!formData.selectedTests.length || !formData.testingAimId"
          native-type="submit"
        >
          {{ $t('buttons.confirm') }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  addDays,
  isWithinInterval,
  getValidationFor,
  getDefault,
} from '@/services'
import TestsTable from './TestsTable'

const ONE_DAY = 1
const ONE_WEEK = 7
const TODAY = new Date(Date.now())

export default {
  name: 'AssignForm',

  components: { TestsTable },

  props: {
    users: { type: Array, required: true },
  },

  data() {
    return {
      isLoading: true,
      tests: [],
      selectedTests: [],
      pickerOptions: {
        disabledDate(date) {
          return !isWithinInterval(new Date(date), {
            start: TODAY,
            end: addDays(TODAY, ONE_WEEK + ONE_DAY),
          })
        },
      },
      formData: getDefault('assignForm'),
      rules: getValidationFor(this, 'assign'),
    }
  },

  created() {
    this.loadTests()
  },

  computed: {
    testingAims() {
      return this.$store.getters['testingAim/testingAims']
    },
  },

  methods: {
    // TODO: get tests from store
    async loadTests() {
      this.isLoading = true

      try {
        this.tests = await this.$store.dispatch(
          'test/fetchTestsForAssignments',
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    submit() {
      this.$refs.form.validate(isValid => {
        if (!isValid) return

        this.$emit('submit', this.formData)
      })
    },

    datePickerUpdateTime(val) {
      this.formData.dueDate = new Date(val.setHours(12, 0, 0, 0))
    },
  },
}
</script>

<style lang="scss" scoped>
.assign-form {
  .el-date-editor,
  .el-select {
    width: 100%;
  }

  .actions {
    margin-top: 10px;

    .submit-btn {
      min-width: 200px;
      margin-top: 20px;
    }
  }
}
</style>
