import registerRules from './registerRules'
import userRules from './userRules'
import subadminRules from './subadminRules'
import subdivisionRules from './subdivisionRules'
import assignRules from './assignRules'
import validators from './validators'

const allRules = {
  registerRules,
  userRules,
  subadminRules,
  subdivisionRules,
  assignRules,
}

const getRuleValueFor = (rules = [], vm) => rules.map(rowRule => {
  const rule = { ...rowRule }
  const { message, validator } = rule

  if (message) { rule.message = message }
  if (validator) { rule.validator = validators[validator].bind(vm) }

  return rule
})

const getValidationCollection = (rules, names, vm) => names
  .reduce((acc, key) => ({
    ...acc,
    [key]: getRuleValueFor(rules[key], vm) || key,
  }), {})

export const getValidationFor = (vm, entityName, fields) => {
  const rules = allRules[`${entityName}Rules`]

  if (!rules) {
    throw new Error(`entityName ${entityName}: No validation rules (${vm.name})`)
  }

  return !fields
    ? getValidationCollection(rules, Object.keys(rules), vm)
    : getValidationCollection(
      rules,
      Array.isArray(fields) ? fields : [fields],
      vm,
    )
}
