<template>
  <section class="users-page">
    <el-row class="mb-04" justify="space-between" align="middle">
      <div>
        <h1>{{ $t(`routes.${$route.name}`) }}</h1>
      </div>

      <div class="ml-1">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({
              name: 'user.new',
              params: { corporationId: filters.corporation }
            })"
          >
            {{ $t('buttons.add') }}
          </el-button>
          <el-button
            plain
            type="warning"
            icon="el-icon-date"
            :disabled="!selected.length"
            @click="assignOpen"
          >
            {{ $t('buttons.assign') }}
          </el-button>
          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="confirmAndDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <UserFilters
      class="mb-04"
      :value="filters"
      v-bind.sync="filters"
      @apply-filters="applyFilters"
      @clear-filters="clearFilters"
    />

    <div class="content">
      <UsersTable
        v-loading="isLoading"
        :index="index"
        :value="tableData"
        :selected="selected"
        :search-query="filters.search"
        @selection-change="selected = $event"
        @show-assignment="isAssignInfoForm = $event"
      />
    </div>

    <el-row justify="space-between" class="mt-1">
      <div>
        <span v-if="total">{{ $t('tableLabels.total') }}: {{ total }}</span>
      </div>

      <div>
        <el-pagination
          hide-on-single-page
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="page"
          :page-size="PAGINATION_SIZE"
          @current-change="showCurrentPage"
        />
      </div>

      <div />
    </el-row>

    <el-dialog
      width="1100px"
      :title="$t('form.name.assign')"
      :visible.sync="isAssignForm"
    >
      <AssignForm
        v-if="isAssignForm"
        :users="selected"
        @submit="assignSubmit"
        @close="assignClose"
      />
    </el-dialog>

    <el-dialog
      width="1400px"
      :title="assignInfoFormTitle"
      :visible="!!isAssignInfoForm"
      @close="closeInfo"
    >
      <ReportInfoOptions
        v-if="isReportOptions"
        :report="isReportOptions"
        @close="isReportOptions = null"
        @test:send-report="sendReport"
      />

      <AssignInfoForm
        v-if="isAssignInfoForm"
        v-show="!isReportOptions"
        :value="isAssignInfoForm"
        @update-data="applyFilters"
        @close="isAssignInfoForm = null"
        @test:use-options="isReportOptions = $event"
      />
    </el-dialog>
  </section>
</template>

<script>
import {
  notify,
  pluck,
  getIndex,
  debounce,
  env,
  getDefault,
  canAssignTest,
} from '@/services'

import ReportInfoOptions from '@/modules/core/components/ReportInfoOptions'
import UserFilters from './components/UserFilters'
import UsersTable from './components/UsersTable'
import AssignForm from './components/AssignForm'
import AssignInfoForm from './components/AssignInfoForm'

export default {
  name: 'UsersPage',

  components: {
    UserFilters,
    UsersTable,
    AssignForm,
    AssignInfoForm,
    ReportInfoOptions,
  },

  data() {
    return {
      isLoading: true,
      isAssignForm: false,
      isAssignInfoForm: null,
      isReportOptions: null,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      page: 1,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },

    assignInfoFormTitle() {
      if (this.isReportOptions) {
        return this.isReportOptions?.isAction === 'test:send-report'
          ? this.$t('buttons.sendReport')
          : this.$t('buttons.viewReport')
      }

      const last = this.isAssignInfoForm?.lastName
      const first = this.isAssignInfoForm?.firstName

      return `${this.$t('form.name.assignmentManagement')}: ${last} ${first}`
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.isLoading) return null
        return this.applyFilters()
      },
    },
  },

  async mounted() {
    try {
      this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
      await this.load()
    } catch (e) {
      console.error(e)
    }
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch('filters/get', {
          key: 'userFilters',
        })

        if (!filters) return null

        this.$set(this, 'filters', filters)

        return filters
      } catch (e) {
        console.error(e)
      }
    },

    async storeFilters() {
      try {
        return await this.$store.dispatch('filters/set', {
          key: 'userFilters',
          value: this.filters,
        })
      } catch (e) {
        console.error(e)
      }
    },

    loadDependencies() {
      const list = [
        'subdivision',
        'position',
        'currentEvent',
        'testingAim',
      ]

      return this.$store.dispatch('dependency/fetchForFilter', list)
    },

    async load() {
      this.isLoading = true

      try {
        const filters = await this.restoreFilters()
        if (!filters) return null

        await this.loadDependencies()

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters,
        }

        const {
          total,
          results,
        } = await this.$store.dispatch('user/fetchAll', { params })

        this.total = total
        this.tableData = results
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
        return results
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async applyFilters() {
      this.isLoading = true

      try {
        this.page = 1

        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true

      try {
        this.filters = getDefault('userFilters')
        this.page = 1

        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      this.isLoading = true

      try {
        this.page = page
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async assignClose() {
      this.isAssignForm = false
      return this.applyFilters()
    },

    assignOpen() {
      const cantBeAssigned = this.selected
        .filter(u => !canAssignTest(u.currentEventId))

      if (cantBeAssigned.length) {
        return notify({
          type: 'warning',
          title: this.$t('titles.cantBeAssigned'),
          message: this.$t('messages.cantBeAssigned'),
        })
      }

      this.isAssignForm = true
    },

    async assignSubmit({ selectedTests, dueDate, testingAimId }) {
      this.isAssignForm = false
      this.isLoading = true

      const assigned = {
        userIds: pluck(this.selected, 'id'),
        testIds: pluck(selectedTests, 'id'),
        dueDate,
        testingAimId,
      }

      try {
        await this.$store.dispatch('assignment/assign', assigned)

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            'messages.successfulAssignment',
            { num: assigned.userIds.length },
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
        await this.assignClose()
        this.selected = []
      }
    },

    async confirmAndDelete() {
      const ids = pluck(this.selected, 'id')

      const isAgree = await this.$confirm(
        this.$t('messages.confirmRemoveUsers', { num: ids.length }),
        { type: 'warning' },
      )

      if (!isAgree) {
        return null
      }

      this.isLoading = true

      try {
        const deletedIds = await this.$store.dispatch('user/delete', { ids })
        // TODO: move to function
        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            'messages.usersDeletedSuccess',
            { num: deletedIds.length },
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    closeInfo() {
      if (this.isReportOptions) {
        this.isReportOptions = null
      } else {
        this.isAssignInfoForm = null
      }
    },

    async sendReport({ report, formData }) {
      try {
        await this.$store.dispatch(
          'reports/sendReport',
          {
            id: report.id,
            options: formData,
          },
        )

        this.isReportOptions = null
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.users-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
