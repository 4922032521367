export default async function routerMiddleware({ next, store }) {
  if (!store.getters.isAppLoaded) {
    try {
      await store.dispatch('setIsAppLoaded', false)
      await store.dispatch('setDefaultLocale')
      const admin = await store.dispatch('auth/autoLogin')

      if (admin) {
        store.dispatch('dependency/fetchDataAfterLogin', { adminId: admin.id })
      }
    } catch (e) {
      console.error(e)
    }

    store.dispatch('setIsAppLoaded', true)
  }

  return next()
}
