<template>
  <div class="test-page">
    <TheLoader v-if="isLoading" />

    <div class="wrapper" v-else>
      <div class="mb-2 mt-2">
        <router-link :to="{ name: 'tests' }">
          {{ $t('buttons.goToTestsList') }}
        </router-link>
      </div>

      <div v-html="test.description_full" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestPage',

  data() {
    return {
      isLoading: true,
      test: null,
      layout: '',
    }
  },

  watch: {
    '$store.getters.locale': {
      immediate: true,
      handler: 'loadTest',
    },
  },

  methods: {
    async loadTest() {
      this.isLoading = true
      const { id } = this.$route.params

      try {
        this.test = await this.$store.dispatch('test/get', { id })

        if (!this.test.description_full) {
          return this.toDev()
        }

        this.isLoading = false
      } catch (e) {
        console.error(e)
        return this.toDev()
      }
    },

    toDev() {
      return this.$router.push({ name: 'dev', query: { redirect: '/tests' } })
    },
  },
}
</script>

<style scoped lang="scss">
.test-page {
  .wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  ::v-deep .container {
    display: flex;
    max-width: 900px;
    margin: 0 auto;

    * {
      color: $--color-text-regular;
      line-height: 1.4rem;
    }

    .main {
      flex: 1;
      margin-right: 20px;
    }

    .aside {
      width: 40%;
    }

    ul {
      padding-left: 20px;

      li {
        list-style: disc;
      }
    }
  }
}
</style>
