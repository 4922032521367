<template>
  <div class="subdivision-form">
    <el-row justify="center" class="mb-4">
      <h2>{{ $t(`form.name.${formData.id ? 'edit' : 'create'}`) }}</h2>
    </el-row>

    <el-form
      ref="form"
      label-width="200px"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item
        prop="parentId"
        :label="$t('form.parentSubdivision.label')"
      >
        <el-select
          clearable
          filterable
          v-model="formData.parentId"
        >
          <el-option
            v-for="s in subdivisions"
            :key="s.id"
            :label="s.fullName"
            :value="s.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="name" :label="$t('form.subdivision.name')">
        <el-input v-model="formData.name" />
      </el-form-item>

      <el-form-item prop="city" :label="$t('form.city.label')">
        <el-input v-model="formData.city" />
      </el-form-item>

      <el-form-item prop="notes" :label="$t('form.notes.label')">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="formData.notes"
        >
        </el-input>
      </el-form-item>

      <div class="actions">
        <router-link class="mr-1" :to="{ name: 'subdivisions' }">
          <el-button plain>{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          class="submit-btn"
          native-type="submit"
          :disabled="isEqual"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep, isEqual, getValidationFor } from '@/services'

export default {
  name: 'SubdivisionForm',

  props: {
    subdivision: { type: Object, required: true },
  },

  data() {
    return {
      isEqual: true,
      formData: cloneDeep(this.subdivision),
      rules: getValidationFor(this, 'subdivision'),
    }
  },

  computed: {
    subdivisions() {
      return this.$store.getters['subdivision/subdivisions']
    },
  },

  watch: {
    subdivision: {
      deep: true,
      handler(subdivision) {
        this.formData = cloneDeep(subdivision)
      },
    },
    formData: {
      deep: true,
      handler(newVal) {
        this.isEqual = isEqual(newVal, this.subdivision)
      },
    },
  },

  methods: {
    submit() {
      this.$refs.form.validate(isValid => {
        if (!isValid) return

        this.$emit('submit', this.formData)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/components/entity-form";
</style>
