import { GET } from '@/plugins/http'
import { initIdb } from '@/plugins/idb'

export default {
  state: () => ({
    roles: [],
    rights: [],
  }),

  mutations: {
    setRoles: (state, payload) => { state.roles = payload },
    setRights: (state, payload) => { state.rights = payload },
  },

  actions: {
    async fetchRoles({ getters, commit }) {
      if (getters.roles.length) {
        return getters.roles
      }

      const { data: roles } = await GET('/roles')
      commit('setRoles', roles)

      return roles
    },

    async fetchRights({ getters, commit }) {
      if (getters.rights.length) {
        return getters.rights
      }

      const { data: rights } = await GET('/roles/rights')
      commit('setRights', rights)

      return rights
    },

    async fetchForFilter({ dispatch }, list) {
      try {
        // load dependency from other stores
        const res = list.map(name => dispatch(`${name}/fetchForFilter`, null, { root: true }))
        return await Promise.all(res)
      } catch (e) {
        console.error(e)
        return list.map(() => [])
      }
    },

    clearAll({ commit }) {
      commit('setRoles', [])
      commit('setRights', [])
      commit('currentEvent/setCurrentEvents', [], { root: true })
      commit('position/setPositions', [], { root: true })
      commit('subdivision/setSubdivisions', [], { root: true })
      commit('testingAim/setTestingAims', [], { root: true })
    },

    async fetchDataAfterLogin({ dispatch }, { adminId }) {
      try {
        await Promise.all([
          initIdb(adminId),
          dispatch('fetchRoles'),
          dispatch('fetchRights'),
          dispatch('reports/fetchTestsInfo', null, { root: true }),
        ])
      } catch (e) {
        console.error(e)
      }
    },
  },

  getters: {
    roles: state => state.roles,
    rights: state => state.rights,
  },
}
