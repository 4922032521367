import { getDefault } from '@/services'
import { setFiltersByAdminId, getFiltersByAdminId } from '@/plugins/idb'

export default {
  actions: {
    async get({ rootGetters, dispatch }, { key }) {
      const admin = rootGetters['auth/admin']

      if (!admin || !admin.id) {
        await dispatch('auth/logout', null, { root: true })
        return rootGetters.router.push({ name: 'login' })
      }

      const defaultFilters = getDefault(key)

      try {
        const filters = await getFiltersByAdminId(admin.id, key)

        if (!filters) {
          await setFiltersByAdminId(admin.id, key, defaultFilters)
        }

        return filters || defaultFilters
      } catch (e) {
        console.error('store get filters error: ', e)
        return setFiltersByAdminId(admin.id, key, defaultFilters)
      }
    },

    async set({ rootGetters }, { key, value }) {
      const admin = rootGetters['auth/admin']

      try {
        return await setFiltersByAdminId(admin.id, key, value)
      } catch (e) {
        return console.error('store set filters error: ', e)
      }
    },
  },
}
