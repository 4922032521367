import { GET, POST, PUT } from '@/plugins/http'

export default {
  actions: {
    async assign(ctx, payload) {
      const { data } = await POST('/assignments/assign', payload)
      return data
    },

    async reassign(ctx, { id }) {
      const { data } = await GET(`/assignments/reassign/${id}`)
      return data
    },

    async getGroup(ctx, { groupId }) {
      const { data } = await GET(`/assignments/group/${groupId}`)
      return data
    },

    // payload = {id, notes}
    async edit(ctx, { id, formData }) {
      const { data } = await PUT(`/assignments/edit/${id}`, formData)
      return data
    },

    async setAimResult(ctx, payload) {
      const { data } = await POST('/aim-result/set', payload)
      return data
    },

    async getHistory(_, { userId }) {
      const { data } = await GET(`/aim-result/user/${userId}`)
      return data
    },
  },
}
