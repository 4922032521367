var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"users-table",attrs:{"stripe":"","border":"","height":"100%","width":"100%","data":_vm.value},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.index,"width":"50"}}),_c('el-table-column',{attrs:{"type":"selection","width":"44"}}),_c('el-table-column',{attrs:{"sortable":"","min-width":"120","prop":"lastName","label":_vm.$t('tableLabels.lastName'),"sort-method":function (a, b) { return a.lastName.localeCompare(b.lastName); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.lastName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"firstName","min-width":"84","label":_vm.$t('tableLabels.firstName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.firstName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"position.name","min-width":"180","label":_vm.$t('tableLabels.position')}}),_c('el-table-column',{attrs:{"prop":"subdivision.fullName","min-width":"180","label":_vm.$t('tableLabels.subdivision')}}),_c('el-table-column',{attrs:{"prop":"current_event.name","width":"180","label":_vm.$t('tableLabels.currentEvent')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{class:['event-btn',
          { 'disable-event': !_vm.canViewAssignments(row.current_event.id) }
        ],attrs:{"plain":"","type":_vm.EVENT_STATUSES[row.current_event.id]},on:{"click":function($event){return _vm.$emit('show-assignment', row)}}},[_vm._v(" "+_vm._s(row.current_event.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"assignments","min-width":"86","label":_vm.$t('tableLabels.assignmentStatuses')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return _vm._l((row.assignments),function(item){return _c('el-tag',{key:item.label,staticClass:"assignment-tag",attrs:{"color":_vm.getAssignmentStatusTag(item.status).color,"type":_vm.getAssignmentStatusTag(item.status).type,"effect":"plain","size":"mini"}})})}}])}),_c('el-table-column',{attrs:{"prop":"testing_aim.name","min-width":"120","label":_vm.$t('tableLabels.testingAim')}}),_c('el-table-column',{attrs:{"prop":"target_position.name","min-width":"180","label":_vm.$t('tableLabels.targetPosition')}}),_c('el-table-column',{attrs:{"label":_vm.$t('tableLabels.operations'),"width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-button-group',[_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.testingHistory')}},[_c('el-button',{attrs:{"icon":"el-icon-time"},on:{"click":function($event){return _vm.$router.push({
              name: 'user.edit',
              params: { id: row.id },
              query: { tab: 'history' }
            })}}})],1),_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.edit')}},[(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
              name: 'user.edit',
              params: { id: row.id }
            })}}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }