import { GET, POST } from '@/plugins/http'

export default {
  state: () => ({
    admin: null,
  }),

  mutations: {
    setAdmin: (state, payload) => { state.admin = payload },
  },

  actions: {
    async login({ commit }, payload) {
      const { data } = await POST('/auth/login', payload)
      commit('setAdmin', data)
      return data
    },

    async autoLogin({ commit }) {
      const { data } = await GET('/auth/autologin')
      commit('setAdmin', data)
      return data
    },

    async logout({ commit }) {
      await GET('/auth/logout')
      commit('setAdmin', null)
    },
  },

  getters: {
    admin: state => state.admin,
    isAuth: state => Boolean(state.admin),
  },
}
