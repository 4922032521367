<template>
  <div class="testing-history">
    <TheLoader v-if="isLoading" />

    <div
      v-else-if="!history.length && !currentEvent"
      class="mt-4 text-center"
    >
      {{ $t('el.table.emptyText') }}
    </div>

    <template v-else>
      <el-collapse v-model="activeTab">
        <el-collapse-item name="activeNow" v-if="currentEvent">
          <template slot="title">
            <div class="title__icon warning">
              <div class="strip" />
              <div class="icon__wrapper">
                <i class="icon el-icon-warning-outline" />
              </div>
            </div>
            <div class="title">
              {{ $t('filters.currentEvent') }}
            </div>
          </template>

          <AssignInfoForm
            :value="user"
            hide-cancel
            @update-data="loadHistory(); $emit('reload-data')"
            @test:use-options="isReportOptions = $event"
          />
        </el-collapse-item>

        <template v-if="history.length">
          <el-collapse-item
            v-for="item in history"
            :key="item.groupId"
            :name="item.groupId"
          >
            <template slot="title">
              <div :class="['title__icon', {
                'not-completed': item.result === CE.NOT_RECOMMEND,
                'completed': item.result === CE.RECOMMEND
               }]"
              >
                <div class="strip" />
                <div class="icon__wrapper">
                  <i
                    :class="['icon', {
                      'el-icon-circle-close': item.result === CE.NOT_RECOMMEND,
                      'el-icon-circle-check': item.result === CE.RECOMMEND
                    }]"
                  />
                </div>
              </div>

              <div class="title">
                <div class="title__col">
                  {{ getDate(item.createdAt) }}
                </div>
                <div class="title__col">
                  {{ testingAims[item.testingAimId] }}
                </div>
                <div class="title__col">
                  {{ positions[item.targetPositionId] }}
                </div>
                <div class="title__col">
                  {{ results[item.result] }}
                </div>
                <div class="title__col">
                  {{ getAdminName(item.admin) }}
                </div>
              </div>
            </template>

            <AssignInfoTable
              readonly
              :value="item.assignments"
              @test:use-options="isReportOptions = $event"
              @row:update="update($event, item.assignments)"
            />
          </el-collapse-item>
        </template>
      </el-collapse>
    </template>

    <el-dialog
      width="1000px"
      :title="assignInfoFormTitle"
      :visible="!!isReportOptions"
      @close="isReportOptions = null"
    >
      <ReportInfoOptions
        v-if="isReportOptions"
        :report="isReportOptions"
        @test:send-report="sendReport"
        @close="isReportOptions = null"
      />
    </el-dialog>
  </div>
</template>

<script>
import { CurrentEvent, AssignmentStatus, getDate } from '@/services'
import ReportInfoOptions from '@/modules/core/components/ReportInfoOptions'
import AssignInfoForm from './AssignInfoForm'
import AssignInfoTable from './AssignInfoTable'

const keyBy = list => list.reduce((acc, aim) => ({
  ...acc,
  [aim.id]: aim.name,
}), {})

export default {
  name: 'TestingHistory',

  components: {
    AssignInfoForm,
    AssignInfoTable,
    ReportInfoOptions,
  },

  props: {
    user: { type: Object, required: true },
  },

  data() {
    return {
      isLoading: true,
      inProgress: null,
      currentEvent: null,
      history: [],
      active: null,
      activeTab: this.user.meta?.groupId ? 'activeNow' : '',
      CE: CurrentEvent,
      isReportOptions: null,
    }
  },

  computed: {
    // TODO replace to global functions
    assignmentStatuses() {
      return Object
        .entries(AssignmentStatus)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [value]: this.$t(`assignmentStatus.${key.toLowerCase()}`),
        }), {})
    },

    results() {
      return Object
        .entries(CurrentEvent)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [value]: this.$t(`currentEvents.${key.toLowerCase()}`),
        }), {})
    },

    positions() {
      const list = this.$store.getters['position/positions']
      return keyBy(list)
    },

    testingAims() {
      const list = this.$store.getters['testingAim/testingAims']
      return keyBy(list)
    },

    assignInfoFormTitle() {
      if (this.isReportOptions) {
        return this.isReportOptions?.isAction === 'test:send-report'
          ? this.$t('buttons.sendReport')
          : this.$t('buttons.viewReport')
      }

      return this.$t('form.name.assignmentManagement')
    },
  },

  mounted() {
    this.loadDependency()
    this.loadHistory()
  },

  methods: {
    getDate,

    async loadDependency() {
      try {
        const list = ['position', 'testingAim']
        await this.$store.dispatch('dependency/fetchForFilter', list)
      } catch (e) {
        console.error(e)
      }
    },

    async loadHistory() {
      this.isLoading = true

      try {
        const { current, history } = await this.$store.dispatch(
          'assignment/getHistory',
          { userId: this.user.id },
        )

        this.history = history || []
        this.currentEvent = current
      } catch (e) {
        this.history = []
        this.currentEvent = null
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    getAdminName(admin) {
      return admin ? `${admin.lastName} ${admin.firstName}` : '-'
    },

    async update({ originData, formData }, list) {
      try {
        const newData = await this.$store.dispatch(
          'assignment/edit',
          {
            id: originData.id,
            formData,
          },
        )

        this.updateRow(originData, newData, list)
      } catch (e) {
        console.error(e)
      }
    },

    updateRow({ id }, newData, list) {
      const index = list.findIndex(row => row.id === id)

      if (index > -1) {
        this.$set(
          list,
          index,
          { ...list[index], ...newData },
        )
      }
    },

    async sendReport({ report, formData }) {
      try {
        await this.$store.dispatch(
          'reports/sendReport',
          {
            id: report.id,
            options: formData,
          },
        )

        this.isReportOptions = null
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">

/deep/.el-collapse-item__header {
  background-color: $grey-50;
}

.title {
  display: flex;
  overflow-y: auto;
  min-height: 46px;

  &__icon {
    height: inherit;
    display: flex;
    padding-right: 20px;

    .strip {
      display: block;
      width: 10px;
      min-width: 0;
      height: inherit;
      min-height: 0;
      margin-right: 8px;
      background-color: $--c-warning;
    }

    .icon__wrapper {
      padding-top: 4px;
      height: inherit;
    }

    .icon {
      color: $--c-warning;
      font-size: 1.4rem;
    }

    &.not-completed {
      .strip {
        background-color: $red-400;
      }
      .icon {
        color: $red-400
      }
    }

    &.completed {
      .strip {
        background-color: $green-300;
      }
      .icon {
        color: $green-300
      }
    }
  }

  &__col {
    padding: 0 10px;
    color: $--color-text-regular;
    line-height: 1rem;
    display: flex;
    align-items: center;

    &:nth-child(1) {
      width: 100px;
    }

    &:nth-child(2) {
      min-width: 240px;
      width: 240px;
    }

    &:nth-child(3) {
      min-width: 300px;
      width: 300px;
    }

    &:nth-child(4) {
      min-width: 200px;
      width: 200px;
    }

    &:nth-child(5) {
      min-width: 240px;
    }
  }
}
</style>
