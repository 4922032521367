<template>
  <div :style="boxSize" class="block-size">
    <slot>
      <img class="img" :src="srcOrPh" :alt="alt">
    </slot>
  </div>
</template>

<script>
const SIZES = {
  xs: 20,
  ml: 30,
  md: 58,
  ls: 80,
  lg: 100,
  xxl: 200,
  xxx: 250,
}

export default {
  name: 'BlockSize',

  props: {
    src: String,
    alt: String,
    size: { type: [String, Number], default: 'md' },
  },

  computed: {
    srcOrPh() {
      return this.src || require('@/assets/icon/placeholder.svg') // eslint-disable-line
    },

    boxSize() {
      if (this.size === 'max') {
        return { height: 'auto', width: '100%' }
      }

      const size = typeof this.size === 'number'
        ? `${this.size}px`
        : `${SIZES[this.size]}px`

      return { height: size, width: size, maxWidth: '100%' }
    },
  },
}
</script>

<style scoped lang="scss">
.block-size {
  display: flex;

  .img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
