var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"stripe":"","border":"","data":_vm.value,"height":"100%","width":"100%"},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.index,"width":"50"}}),_c('el-table-column',{attrs:{"type":"selection","width":"44"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('tableLabels.name'),"min-width":"230","sort-method":function (a, b) { return a.name.localeCompare(b.name); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"city","label":_vm.$t('tableLabels.city'),"width":"180","sort-method":function (a, b) { return a.city.localeCompare(b.city); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.city)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"parentFullName","label":_vm.$t('tableLabels.subordinateTo'),"min-width":"230"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"admins","label":_vm.$t('tableLabels.admins'),"width":"172","sort-method":function (a, b) { return Number(a.admins) - Number(b.admins); }}}),_c('el-table-column',{attrs:{"sortable":"","prop":"users","label":_vm.$t('tableLabels.users'),"width":"140","sort-method":function (a, b) { return Number(a.users) - Number(b.users); }}}),_c('el-table-column',{attrs:{"prop":"notes","label":_vm.$t('tableLabels.notes'),"min-width":"140"}}),_c('el-table-column',{attrs:{"label":_vm.$t('tableLabels.operations'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.edit')}},[(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
            name: 'subdivision.edit',
            params: { id: row.id }
          })}}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }