export const CurrentEvent = {
  NOT_ASSIGNED: 1,
  ASSIGNED: 2,
  NOT_RECOMMEND: 3,
  RECOMMEND: 4,
}

export const AssignmentStatus = {
  NOT_STARTED: 1,
  NOT_COMPLETED: 2,
  COMPLETED: 3,
}
