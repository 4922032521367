export default {
  langCode: 'UA',
  langName: 'Українська',

  routes: {
    home: 'Домашня сторінка',
    about: 'Про нас',
    administration: 'Адміністрування',
    analytics: 'Аналітика',
    error: 'Помилка',
    login: 'Вхід',
    logout: 'Вихід',
    reports: {
      assignment: 'Звіт по тесту'
    },
    solutions: 'Наші рішення',
    sub_admins: 'Адміністратори підрозділів',
    subdivisions: 'Підрозділи',
    tests: 'Тести',
    users: 'Персонал'
  },

  core: {
    about: 'Про D.O.T.S.',
    copyright: 'Авторське право 2021 D.O.T.S.',
    development: 'Вибачте, нажаль, сторінка знаходиться у розробці.',
    rightsReserved: 'Усі права захищені',
    service: 'Сервіс',
    appDescription: 'Цифрова офісна система тестування'
  },

  auth: {
    logoutConfirm: 'Ви впевнені що бажаєте вийти з системи?',
  },

  tests: {
    noTest: 'Дані відсутні, зверніться в тех. підтримку',
    pass: 'Пройдено',
    questionsCount: 'Кількість запитань',
    responses: 'Відповіді на запитання',
    timeLimit: 'Орієнтовний час'
  },

  filters: {
    currentEvent: 'Поточна подія',
    decide: 'Прийняти рішення',
    position: 'Посада',
    search: 'Пошук...',
    searchByNameOrCity: 'Шукати за іменем або містом',
    showChildren: 'Показати дочірні',
    status: 'Статус',
    subdivision: 'Підрозділ',
    targetPosition: 'Цільова посада',
    testingAim: 'Мета тестування'
  },

  user: {
    profile: 'Профіль',
    testingHistory: 'Історія тестування',
    password: 'Пароль',
    newPassword: 'Новий пароль',
    admin: 'Адміністратор',
    email: 'Email',
    subdivision: 'Підрозділ',
    position: 'Посада',
    oldPassword: 'Старий пароль',
    newPasswordConfirm: 'Підтвердження нового пароля',
  },

  tableLabels: {
    actions: 'Дії',
    admins: 'Адміністратори',
    assignDate: 'Дата призначення',
    assignmentStatuses: 'Статуси призначень',
    city: 'Місто',
    current_event: 'Поточна подія',
    currentEvent: 'Поточна подія',
    contacts: 'Контакти',
    dueDate: 'Дедлайн',
    factor: 'Фактор',
    firstName: 'Ім`я',
    lastName: 'Прізвище',
    name: 'Назва',
    notes: 'Примітки',
    operations: 'Дії',
    position: 'Посада',
    role: 'Роль',
    search: 'Пошук',
    started: 'Розпочато',
    status: 'Статус',
    subdivision: 'Підрозділ',
    subordinateTo: 'Підпорядковується до',
    targetPosition: 'Цільова посада',
    testing_aim: 'Мета тестування',
    testingAim: 'Мета тестування',
    testingDuration: 'Тривалість тестування',
    testName: 'Тест',
    total: 'Загалом',
    users: 'Працівники',
    value: 'Значення'
  },

  reports: {
    detailing: 'Деталізація',
    language: 'Переклад',
    reportType: 'Тип звіту',
    reportSubmitted: 'Звіт відправлено',
    reportSubmittedMessage: 'Звіт про завершений тест "{name}" відправлено'
  },

  reportTypes: {
    "base": 'Базовий',
    "standard": 'Стандарт'
  },

  reportDetailing: {
    base: 'Коротка',
    precise: 'Розширена'
  },

  assignmentStatus: {
    not_started: 'Призначено',
    not_completed: 'Не завершено',
    completed: 'Завершено'
  },

  currentEvents: {
    not_assigned: 'Не призначено',
    assigned: 'Призначено',
    not_recommend: 'Не рекомендовано',
    recommend: 'Рекомендовано',
  },

  messages: {
    success: 'Успіх',
    warning: 'Увага',
    showChildren: 'Для показу дочірніх підрозділів, будь ласка, виберіть лише один підрозділ',
    cantBeAssigned: 'Деяким з обраних осіб вже призначено тестування. Оберіть тих, у кого поточна подія в статусі "Не призначено", "Рекомендовано" чи "Не рекомендовано"',
    adminActivationSuccess: 'Адміністратора успішно розблоковано | Адміністратора успішно заблоковано',
    adminsRemoved: 'Успішно видалено {num} адміністраторів',
    subdivisionRemoved: 'Успішно видалено {num} підрозділів',
    userCreatedSuccess: 'Користувача <b>{name}</b> створено',
    userUpdatedSuccess: 'Дані користувача <b>{name}</b> оновлено',
    usersDeletedSuccess: 'Видалено {num} користувачів',
    subdivisionCreatedSuccess: 'Підрозділ <b>{name}</b> створено',
    subdivisionUpdatedSuccess: 'Підрозділ <b>{name}</b> оновлено',
    subdivisionInNotEmptyTitle: 'Деякий з вибраних підрозділів не порожній',
    subdivisionInNotEmptyDescription: 'Деякі підрозділи <b style = "color: red">мають адміністраторів або користувачів</b>, будь-ласка перевірте це і перепризначте до іншого підрозділу або видаліть їх.',
    subdivisionChildrenInNotEmptyDescription: 'Деякі підрозділи <b style = "color: red">мають дочірні підрозділи</b>, будь-ласка перевірте це і перепризначити до іншого підрозділу або видалити їх.',
    successfulAssignment: 'Вдало призначено тести {num} користувачам',
    confirmRemoveUsers: 'Ви впевнені що бажаєте видалити {num} користувачів?'
  },

  titles: {
    cantBeAssigned: 'Неможливо призначити',
    changesApplied: 'Зміни застосовано'
  },

  buttons: {
    exit: 'Вийти',
    add: 'Додати',
    apply: 'Застосувати',
    assign: 'Призначити',
    back: 'Повернутися',
    backToHome: 'Повернутися на головну',
    cancel: 'Скасувати',
    clearFilters: 'Очистити фільтри',
    confirm: 'Підтвердити',
    delete: 'Видалити',
    edit: 'Редагувати',
    goToTestsList: 'До списку тестів',
    login: 'Увійти',
    print: 'Друкувати',
    reassign: 'Перепризначити',
    save: 'Зберегти',
    close: 'Закрити',
    viewReport: 'Переглянути звіт',
    view: 'Переглянути',
    sendReport: 'Надіслати звіт',
    send: 'Надіслати',
    signup: 'Зареєструватися',
    testingHistory: 'Історія тестування',
    enabled: 'Активовано',
    disabled: 'Заблоковано',
    enable: 'Активувати',
    disable: 'Заблокувати'
  },

  form: {
    name: {
      login: 'Увійти',
      signup: 'Зареєструватися',
      edit: 'Редагувати профіль',
      create: 'Створити профіль',
      assign: 'Призначити тестування',
      assignmentManagement: 'Керування призначеннями'
    },
    email: {
      label: 'Електронна пошта',
    },
    password: {
      label: 'Пароль',
      length: 'Допустима кількість символів від {min} до {max}'
    },
    confirmPassword: {
      label: 'Підтвердити пароль',
    },
    firstName: {
      label: 'Імʼя',
    },
    lastName: {
      label: 'Прізвище',
    },
    birthday: {
      label: 'Дата народження',
    },
    gender: {
      label: 'Стать',
      male: 'Чоловік',
      female: 'Жінка',
    },
    subdivision: {
      label: 'Підрозділ',
      name: 'Назва',
    },
    city: {
      label: 'Місто',
    },
    parentSubdivision: {
      label: 'Підпорядковується до:',
      name: 'Назва',
    },
    position: {
      label: 'Посада',
    },
    testingAim: {
      label: 'Мета тестування',
      name: 'Назва',
    },
    targetPosition: {
      label: 'Цільова посада',
    },
    notes: {
      label: 'Примітки',
    },
    role: {
      label: 'Роль',
    },
    contacts: {
      label: 'Контакти',
    },
    dueDate: {
      label: 'Термін виконання до',
    },
    selectTests: {
      label: 'Обрати тест',
    },
  },

  validation: {
    core: {
      required: 'Це поле обов\'язкове',
      min: 'Мінімальна кількість символів {num}',
      max: 'Максимальна кількість символів {num}',
      minMax: 'Допустима кількість символів від {min} до {max}'
    },
    email: {
      pattern: 'Будь-ласка введіть коректну адресу'
    },
    confirmPasswordError: 'Паролі не співпадають',
    newPasswordLikeOld: 'Новий пароль має відрізнятися від старого'
  },

  el: {
    datepicker: {
      assign: {
        placeholder: 'Будь-ласка оберіть дату до якої дійсне призначення',
      },
      now: 'Зараз',
      today: 'Сьогодні',
      cancel: 'Відміна',
      clear: 'Очистити',
      confirm: 'OK',
      selectDate: 'Обрати дату',
      selectTime: 'Обрати час',
      startDate: 'Дата початку',
      startTime: 'Час початку',
      endDate: 'Дата завершення',
      endTime: 'Час завершення',
      prevYear: 'Попередній рік',
      nextYear: 'Наступний рік',
      prevMonth: 'Попередній місяць',
      nextMonth: 'Наступний місяць',
      year: '',
      month1: 'Січень',
      month2: 'Лютий',
      month3: 'Березень',
      month4: 'Квітень',
      month5: 'Травень',
      month6: 'Червень',
      month7: 'Липень',
      month8: 'Серпень',
      month9: 'Вересень',
      month10: 'Жовтень',
      month11: 'Листопад',
      month12: 'Грудень',
      weeks: {
        sun: 'Нд',
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
      },
      months: {
        jan: 'Січ',
        feb: 'Лют',
        mar: 'Бер',
        apr: 'Кві',
        may: 'Тра',
        jun: 'Чер',
        jul: 'Лип',
        aug: 'Сер',
        sep: 'Вер',
        oct: 'Жов',
        nov: 'Лис',
        dec: 'Гру',
      },
    },
    messagebox: {
      confirm: 'Підтвердити'
    },
    select: {
      noMatch: 'Співпадінь не знайдено',
      noData: 'Немає даних',
      placeholder: 'Обрати',
    },
    table: {
      emptyText: 'Немає даних',
    },
  },
}
