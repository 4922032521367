<template>
  <VueTextHighlight :queries="queries">
    <slot />
  </VueTextHighlight>
</template>

<script>
import VueTextHighlight from 'vue-text-highlight'

export default {
  name: 'TextHighlight',

  components: {
    VueTextHighlight,
  },

  props: {
    queries: String,
  },
}
</script>
