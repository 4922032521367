import { GET, POST, PUT } from '@/plugins/http'

export default {
  actions: {
    // TODO => getAll
    async fetchAll(ctx, payload) {
      const {
        data: { total, results },
      } = await GET('/sub-admins', payload)
      return { total: Number(total), results }
    },

    async get(ctx, { id }) {
      const { data } = await GET(`/sub-admins/${id}`)
      return data
    },

    async create(ctx, payload) {
      const { data } = await POST('/sub-admins', payload)
      return data
    },

    async update(ctx, payload) {
      const { data } = await PUT(`/sub-admins/${payload.id}`, payload)
      return data
    },

    async delete(ctx, payload) {
      const { data: ids } = await POST('/sub-admins/delete', payload)
      return ids
    },
  },
}
