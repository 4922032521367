<template>
  <section class="subdivision-page entity-edit-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="page-title" v-if="subdivision">
        <h1>{{ subdivision.name }} {{ subdivision.city }}</h1>
      </div>

      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-notebook-2" /> {{ $t('user.profile') }}
          </span>

          <SubdivisionForm
            class="form"
            :subdivision="subdivision"
            v-loading="isVLoading"
            @submit="submit"
          />
        </el-tab-pane>
      </el-tabs>
    </template>
  </section>
</template>

<script>
import { notify, getDefault } from '@/services'
import SubdivisionForm from './components/SubdivisionForm'

export default {
  name: 'SubdivisionPage',

  components: {
    SubdivisionForm,
  },

  data() {
    return {
      isLoading: true,
      isVLoading: false,
      subdivision: null,
    }
  },

  created() {
    this.$store.dispatch('subdivision/fetchForFilter')
    this.load()
  },

  methods: {
    async load() {
      this.isLoading = true
      const { id } = this.$route.params

      try {
        this.subdivision = id
          ? await this.$store.dispatch(
            'subdivision/get',
            { id },
          )
          : getDefault('subdivision')
      } catch (e) {
        console.error(e)
        this.subdivision = getDefault('subdivision')
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isVLoading = true

      try {
        // TODO: implements update only changed fields
        const payload = {
          ...formData,
          parentId: formData.parentId || null,
        }

        this.subdivision = await this.$store.dispatch(
          `subdivision/${formData.id ? 'update' : 'create'}`,
          payload,
        )

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            `messages.subdivision${formData.id ? 'Created' : 'Updated'}Success`,
            { name: this.subdivision.name },
          ),
        }, true)

        await this.$store.dispatch(
          'subdivision/fetchForFilter',
          { force: true },
        )

        return this.$router.push({ name: 'subdivisions' })
      } catch (e) {
        console.error(e)
      } finally {
        this.isVLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/components/entity-edit-page.scss";
</style>
