<template>
  <el-table
    stripe
    border
    :data="value"
    height="100%"
    width="100%"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" :index="index" width="50" />

    <el-table-column type="selection" width="44" />

    <el-table-column
      sortable
      min-width="140"
      prop="lastName"
      :label="$t('tableLabels.lastName')"
      :sort-method="(a, b) => a.lastName.localeCompare(b.lastName)"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.lastName }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      prop="firstName"
      :label="$t('tableLabels.firstName')"
      min-width="90"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.firstName }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      prop="subdivision.fullName"
      :label="$t('tableLabels.subdivision')"
      min-width="230"
    />

    <el-table-column
      prop="position.name"
      :label="$t('tableLabels.position')"
      min-width="104"
    />

    <el-table-column
      prop="role.displayName"
      :label="$t('tableLabels.role')"
      min-width="184"
    />

    <el-table-column
      prop="isActive"
      :label="$t('tableLabels.status')"
      width="80"
    >
      <template slot-scope="{ row }">
        {{ row.isActive ? 'Active' : 'Disabled' }}
      </template>
    </el-table-column>

    <el-table-column
      prop="contacts"
      :label="$t('tableLabels.contacts')"
      min-width="180"
    />

    <el-table-column
      :label="$t('tableLabels.operations')"
      width="108"
    >
      <!-- TODO: implement operations -->
      <template slot-scope="{ row }">
        <el-button-group>
          <el-tooltip :content="$t('buttons.edit')">
            <el-button
              v-if="!row.deletedAt"
              plain
              type="primary"
              icon="el-icon-edit"
              @click="$router.push({
                name: 'sub_admin.edit',
                params: { id: row.id }
              })"
            />
          </el-tooltip>

          <el-tooltip
            :content="$t(`buttons.${row.isActive ? 'disable' : 'enable'}`)"
          >
            <el-button
              plain
              :icon="`el-icon-video-${row.isActive ? 'pause' : 'play'}`"
              :type="row.isActive ? 'warning' : 'success'"
              v-if="!row.deletedAt"
              @click="$emit('toggle-activation', row)"
            />
          </el-tooltip>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import filteredTableMixin from '@/modules/core/mixins/filteredTableMixin'

export default {
  name: 'SubAdminsTable',

  mixins: [filteredTableMixin],
}
</script>
