<template>
  <el-row justify="center" class="the-footer">
    {{ $t('core.about') }} |
    {{ $t('core.service') }} |
    {{ $t('core.copyright') }} |
    {{ $t('core.rightsReserved') }}
  </el-row>
</template>

<script>
export default {
  name: 'TheFooter',
}
</script>

<style scoped lang="scss">
.the-footer {
  padding: 4px;
  color: $--color-text-regular;
}
</style>
