<template>
  <el-table
    stripe
    border
    ref="testsList"
    style="width: 100%"
    :data="value"
    max-height="300"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" width="44" />
    <el-table-column type="selection" width="44" />
    <el-table-column
      sortable
      width="300"
      prop="name"
      label="Name"
      :sort-method="(a, b) => a.name.localeCompare(b.name)"
    />
    <el-table-column
      label="Description"
      prop="description"
      min-width="200"
    />
  </el-table>
</template>

<script>
export default {
  name: 'TestsTable',

  props: {
    value: { type: Array, required: true },
    selectedTests: { type: Array, required: true },
  },
}
</script>
