import { PUT } from '@/plugins/http'

export default {
  actions: {
    async update(ctx, payload) {
      const { data: admin } = await PUT('/admins', payload)
      return admin
    },
  },
}
