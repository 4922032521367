<template>
  <section class="about-page">
    <img src="@/assets/img/aboutUs.png" :alt="$t('routes.about')" />
  </section>
</template>

<script>
export default {
  name: 'AboutPage',
}
</script>

<style lang="scss" scoped>
.about-page {
  display: flex;

  img {
    margin: 40px auto;
    width: 90%;
  }
}
</style>
