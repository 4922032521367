var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"stripe":"","border":"","data":_vm.value,"height":"100%","width":"100%"},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.index,"width":"50"}}),_c('el-table-column',{attrs:{"type":"selection","width":"44"}}),_c('el-table-column',{attrs:{"sortable":"","min-width":"140","prop":"lastName","label":_vm.$t('tableLabels.lastName'),"sort-method":function (a, b) { return a.lastName.localeCompare(b.lastName); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.lastName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"firstName","label":_vm.$t('tableLabels.firstName'),"min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextHighlight',{attrs:{"queries":_vm.searchQuery}},[_vm._v(" "+_vm._s(row.firstName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"subdivision.fullName","label":_vm.$t('tableLabels.subdivision'),"min-width":"230"}}),_c('el-table-column',{attrs:{"prop":"position.name","label":_vm.$t('tableLabels.position'),"min-width":"104"}}),_c('el-table-column',{attrs:{"prop":"role.displayName","label":_vm.$t('tableLabels.role'),"min-width":"184"}}),_c('el-table-column',{attrs:{"prop":"isActive","label":_vm.$t('tableLabels.status'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isActive ? 'Active' : 'Disabled')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"contacts","label":_vm.$t('tableLabels.contacts'),"min-width":"180"}}),_c('el-table-column',{attrs:{"label":_vm.$t('tableLabels.operations'),"width":"108"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button-group',[_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.edit')}},[(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
              name: 'sub_admin.edit',
              params: { id: row.id }
            })}}}):_vm._e()],1),_c('el-tooltip',{attrs:{"content":_vm.$t(("buttons." + (row.isActive ? 'disable' : 'enable')))}},[(!row.deletedAt)?_c('el-button',{attrs:{"plain":"","icon":("el-icon-video-" + (row.isActive ? 'pause' : 'play')),"type":row.isActive ? 'warning' : 'success'},on:{"click":function($event){return _vm.$emit('toggle-activation', row)}}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }