<template>
  <el-form
    class="login-form"
    ref="form"
    :rules="rules"
    :model="formData"
    @submit.native.prevent="submit"
  >
    <el-form-item :label="$t('form.email.label')" prop="email">
      <el-input type="email" v-model="formData.email" />
    </el-form-item>

    <el-form-item :label="$t('form.password.label')" prop="password">
      <el-input
        show-password
        type="password"
        autocomplete="off"
        v-model="formData.password"
      />
    </el-form-item>

    <div class="actions mt-4">
      <slot v-bind="{ isLoading }">
        <el-button
          type="primary"
          class="submit-btn"
          native-type="submit"
          :loading="isLoading"
        >
          {{ $t('buttons.login') }}
        </el-button>
      </slot>
    </div>
  </el-form>
</template>

<script>
import {
  getDefault,
  getValidationFor,
  errNotify,
} from '@/services'

export default {
  name: 'LoginForm',

  data() {
    return {
      isLoading: false,
      formData: getDefault('register'),
      rules: getValidationFor(this, 'register'),
    }
  },

  methods: {
    clearForm() {
      this.formData = getDefault('register')
      this.$refs.form.clearValidate()
    },

    submit() {
      this.$refs.form.validate(async isValid => {
        if (!isValid) return false

        this.isLoading = true

        try {
          const { email, password } = this.formData
          const admin = await this.$store.dispatch(
            'auth/login',
            { email, password },
          )

          await this.$store.dispatch(
            'dependency/fetchDataAfterLogin',
            { adminId: admin.id },
          )

          this.$emit('auth:success')

          this.clearForm()
        } catch (e) {
          console.error(e)
          errNotify(e)
          this.$emit('auth:error', e)
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login-form {
  .actions {
    display: flex;
    justify-content: space-around;

    .submit-btn {
      min-width: 200px;
    }
  }
}
</style>
