<template>
  <section class="solutions-page">
    <img
      src="@/assets/img/ourSolutions.png"
      :alt="$t('routes.solutions')"
    />
  </section>
</template>

<script>
export default {
  name: 'SolutionsPage',
}
</script>

<style lang="scss" scoped>
.solutions-page {
  display: flex;

  img {
    margin: 40px auto;
    width: 90%;
  }
}
</style>
