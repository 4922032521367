import TextHighlight from '@/modules/core/components/TextHighlight'

export default {
  components: {
    TextHighlight,
  },

  props: {
    index: { type: Number, required: true },
    value: { type: Array, required: true },
    searchQuery: { type: String, default: '' },
    selected: { type: Array, required: true },
  },
}
