<template>
  <section class="t-page">
  </section>
</template>

<script>

export default {
  name: 'TPage',

  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.t-page {
  height: 100%;
}
</style>
