<template>
  <el-row class="subdivision-filters table-filter">
    <div class="mr-1">
      <el-button
        class="filter-btn"
        plain
        type="danger"
        @click="$emit('clear-filters')"
      >
        {{ $t('buttons.clearFilters') }}
      </el-button>
    </div>

    <div class="wrap">
      <el-input
        clearable
        class="search"
        :placeholder="$t('filters.searchByNameOrCity')"
        :value="value.search"
        @input="emit('search', $event)"
      />

      <el-checkbox
        border
        :value="value.showChildren"
        @change="emit('showChildren', $event)"
      >
        {{ $t('filters.showChildren') }}
      </el-checkbox>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'SubdivisionFilters',

  props: {
    value: { type: Object, required: true },
  },

  methods: {
    emit(name, value) {
      this.$emit(`update:${name}`, value)
    },
  },
}
</script>

<style scoped>
.search {
  width: 260px;
}
</style>
