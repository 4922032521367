export default {
  langCode: 'EN',
  langName: 'English',

  routes: {
    home: 'Home page',
    about: 'About us',
    administration: 'Administration',
    analytics: 'Analytics',
    error: 'Error',
    login: 'Login',
    logout: 'Logout',
    reports: {
      assignment: 'Test report'
    },
    solutions: 'Our solutions',
    sub_admins: 'Admins',
    subdivisions: 'Subdivisions',
    tests: 'Tests',
    users: 'Users',
  },

  core: {
    about: 'About D.O.T.S.',
    copyright: 'Copyright 2021 D.O.T.S.',
    development: 'Sorry, this page is under construction.',
    rightsReserved: 'All rights reserved',
    service: 'Service',
    appDescription: 'Digital Office Test System'
  },

  auth: {
    logoutConfirm: 'Are you sure you want to logout?',
  },

  tests: {
    noTest: 'No data, contact to support',
    pass: 'Pass %',
    questionsCount: 'Questions',
    responses: 'Item responses',
    timeLimit: 'Time limit'
  },

  filters: {
    currentEvent: 'Current event',
    decide: 'Decide',
    position: 'Position',
    search: 'Search...',
    searchByNameOrCity: 'Search by name or city',
    showChildren: 'Show children',
    status: 'Status',
    subdivision: 'Subdivision',
    targetPosition: 'Target position',
    testingAim: 'Testing aim'
  },

  user: {
    profile: 'Profile',
    testingHistory: 'Testing history',
    password: 'Password',
    newPassword: 'New password',
    admin: 'Admin',
    email: 'Email',
    subdivision: 'Subdivision',
    position: 'Position',
    oldPassword: 'Old password',
    newPasswordConfirm: 'New password confirm',
  },

  tableLabels: {
    actions: 'Actions',
    admins: 'Administrators',
    assignDate: 'Date of appointment',
    assignmentStatuses: 'Assignment statuses',
    city: 'City',
    current_event: 'Current event',
    currentEvent: 'Current event',
    contacts: 'Contacts',
    dueDate: 'Deadline',
    factor: 'Factor',
    firstName: 'First name',
    lastName: 'Last name',
    name: 'Name',
    notes: 'Notes',
    operations: 'Operations',
    position: 'Position',
    role: 'Role',
    search: 'Search',
    started: 'Started',
    status: 'Status',
    subdivision: 'Subdivision',
    subordinateTo: 'Subordinate to',
    targetPosition: 'Target position',
    testing_aim: 'Testing aim',
    testingAim: 'Testing aim',
    testingDuration: 'Testing duration',
    testName: 'Test name',
    total: 'Total',
    users: 'Users',
    value: 'Value'
  },

  reports: {
    detailing: 'Detailing',
    language: 'Language',
    reportType: 'Report type',
    reportSubmitted: 'Report submitted',
    reportSubmittedMessage: 'Test completion report "{name}" sent'
  },

  reportTypes: {
    "base": 'Base',
    "standard": 'Standard'
  },

  reportDetailing: {
    base: 'Short',
    precise: 'Extended'
  },

  assignmentStatus: {
    not_started: 'Assigned',
    not_completed: 'Not Completed',
    completed: 'Completed'
  },

  currentEvents: {
    not_assigned: 'Not Assigned',
    assigned: 'Assigned',
    not_recommend: 'Not Recommend',
    recommend: 'Recommend',
  },

  messages: {
    success: 'Success',
    warning: 'Warning',
    showChildren: 'For show children please select only one subdivision',
    cantBeAssigned: 'Some of the selected individuals have already been assigned testing. Select those who have the current event in the status "Not assigned", "Recommend" or "Not recommend"',
    adminActivationSuccess: 'Admin Enabled successfully | Admin Disabled successfully',
    adminsRemoved: '{num} administrators successfully removed',
    subdivisionRemoved: '{num} subdivisions successfully removed',
    userCreatedSuccess: 'User <b>{name}</b> created successfully',
    userUpdatedSuccess: 'User info <b>{name}</b> updated successfully',
    usersDeletedSuccess: 'Successful deleted {num} users',
    subdivisionCreatedSuccess: 'Subdivision <b>{name}</b> created successfully',
    subdivisionUpdatedSuccess: 'Subdivision <b>{name}</b> updated successfully',
    subdivisionInNotEmptyTitle: 'Some of selected subdivisions is not empty',
    subdivisionInNotEmptyDescription: 'Some of subdivisions <b style="color:red">has admins or users</b>, please check it and reassign to another subdivision or delete they.',
    subdivisionChildrenInNotEmptyDescription: 'Some of subdivisions <b style="color:red">has children subdivision</b>, please check it and reassign to another subdivision or delete they.',
    successfulAssignment: 'Successful assignment of {num} users',
    confirmRemoveUsers: 'Are you sure you want to delete {num} users?'
  },

  titles: {
    cantBeAssigned: 'Can\'t be assigned.',
    changesApplied: 'Changes applied'
  },

  buttons: {
    exit: 'Exit',
    add: 'Add',
    apply: 'Apply',
    assign: 'Assign',
    back: 'Go back',
    backToHome: 'Back to home',
    cancel: 'Cancel',
    clearFilters: 'Clear filters',
    confirm: 'Confirm',
    delete: 'Delete',
    edit: 'Edit',
    goToTestsList: 'Go to tests list',
    login: 'Login',
    print: 'Print',
    reassign: 'Assign again',
    save: 'Save',
    close: 'Close',
    viewReport: 'View report',
    view: 'View',
    sendReport: 'Send report',
    send: 'Send',
    signup: 'Signup',
    testingHistory: 'Testing History',
    enabled: 'Enable',
    disabled: 'Disable',
    enable: 'Enable',
    disable: 'Disable'
  },

  form: {
    name: {
      login: 'Login',
      signup: 'Signup',
      edit: 'Edit profile',
      create: 'Create profile',
      assign: 'Assign tests',
      assignmentManagement: 'Assignment Management'
    },
    email: {
      label: 'Email',
      placeholder: 'Please input your email',
    },
    password: {
      label: 'Password',
      placeholder: 'Please input your password',
      length: 'Valid number of characters from {min} to {max}'
    },
    confirmPassword: {
      label: 'Confirm password',
    },
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    birthday: {
      label: 'Birthday',
      placeholder: 'Enter your last name'
    },
    gender: {
      label: 'Gender',
      male: 'Male',
      female: 'Female',
    },
    subdivision: {
      label: 'Subdivision',
      name: 'Name',
    },
    city: {
      label: 'City',
    },
    parentSubdivision: {
      label: 'Parent subdivision',
      name: 'Name',
    },
    position: {
      label: 'Position',
    },
    testingAim: {
      label: 'Testing aim',
      name: 'Testing aim name',
    },
    targetPosition: {
      label: 'Target position',
    },
    notes: {
      label: 'Notes',
    },
    role: {
      label: 'Role',
    },
    contacts: {
      label: 'Contacts',
    },
    dueDate: {
      label: 'Due date',
    },
    selectTests: {
      label: 'Select test',
    },
  },

  validation: {
    core: {
      required: 'This field is required',
      min: 'Minimum number of characters {num}',
      max: 'Maximum number of characters {num}',
      minMax: 'Valid number of characters from {min} to {max}'
    },
    email: {
      pattern: 'Please input correct email address'
    },
    confirmPasswordError: 'Passwords do not match',
    newPasswordLikeOld: 'The new password must be different from the old password'
  },

  el: {
    datepicker: {
      assign: {
        placeholder: 'Please select due date',
      },
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start date',
      startTime: 'Start time',
      endDate: 'End date',
      endTime: 'End time',
      prevYear: 'Previous year',
      nextYear: 'Next year',
      prevMonth: 'Previous month',
      nextMonth: 'Next month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    messagebox: {
      confirm: 'Confirm'
    },
    select: {
      noMatch: 'No match',
      noData: 'No data',
      placeholder: 'Select',
    },
    table: {
      emptyText: 'No data',
    },
  },
}
