import { GET } from '@/plugins/http'

export default {
  state: () => ({
    positions: [],
  }),

  mutations: {
    setPositions: (state, payload) => { state.positions = payload },
  },

  actions: {
    async fetchForFilter({ commit, getters }) {
      if (getters.positions.length) {
        return getters.positions
      }

      const { data: positions } = await GET('/positions')

      commit('setPositions', positions)

      return positions
    },
  },

  getters: {
    positions: state => state.positions,
  },
}
