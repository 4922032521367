import { GET } from '@/plugins/http'

export default {
  state: () => ({
    testingAims: [],
  }),

  mutations: {
    setTestingAims: (state, payload) => { state.testingAims = payload },
  },

  actions: {
    async fetchForFilter({ commit, getters }) {
      if (getters.testingAims.length) {
        return getters.testingAims
      }

      const { data } = await GET('/testing-aims')

      commit('setTestingAims', data)

      return data
    },
  },

  getters: {
    testingAims: state => state.testingAims,
  },
}
