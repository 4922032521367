export default {
  oldPasswordValidator(rule, oldPassword, callback) {
    const { newPassword } = this.formData

    if (newPassword && !oldPassword) {
      callback(new Error(this.$t('validation.core.required')))
    } else {
      callback()
    }
  },

  newPasswordValidator(rule, newPassword, callback) {
    const { oldPassword } = this.formData

    if (newPassword && newPassword === oldPassword) {
      callback(new Error(this.$t('validation.newPasswordLikeOld')))
    } else {
      callback()
    }
  },

  newPasswordConfirmValidator(rule, newPasswordConfirm, callback) {
    const { newPassword } = this.formData

    if (newPassword !== newPasswordConfirm) {
      callback(new Error(this.$t('validation.confirmPasswordError')))
    } else {
      callback()
    }
  },
}
