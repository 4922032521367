import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import intervalToDuration from 'date-fns/intervalToDuration'

export { default as addDays } from 'date-fns/addDays'
export { default as isWithinInterval } from 'date-fns/isWithinInterval'

export const getTimeZone = () => Intl
  .DateTimeFormat()
  .resolvedOptions()
  .timeZone

const addZero = t => ((t < 10) ? `0${t}` : t)

// https://date-fns.org/v2.22.1/docs/intervalToDuration
export const formattedTime = s => {
  const sec = Number(s) ? s : 0

  const duration = intervalToDuration({ start: 0, end: sec * 1000 })
  const { hours, minutes, seconds } = duration

  return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`
}

// https://date-fns.org/v2.22.1/docs/format
export const getDate = (date, formatString = 'yyyy-MM-dd') => (
  date
    ? format(parseISO(date), formatString)
    : '----------'
)
