<template>
  <div class="app">
    <component :is="layout">
      <router-view />
    </component>

    <AuthDialog />
  </div>
</template>

<script>
import { eventBus } from '@/services'
import DefaultLayout from './DefaultLayout'

export default {
  name: 'App',

  components: {
    DefaultLayout,
    /* eslint-disable max-len */
    EmptyLayout: () => import(/* webpackChunkName: "ElTableColumn" */ './EmptyLayout'),
    AuthDialog: () => import(/* webpackChunkName: "DefaultLayout" */ '@/modules/auth/components/AuthDialog'),
    /* eslint-enable max-len */
  },

  created() {
    eventBus.$on('router:push', ag => this.$router.push(ag))
  },

  computed: {
    layout() {
      return this.$route?.meta?.layout || 'DefaultLayout'
    },
  },
}
</script>

<style lang="scss" scoped>
.app {
  min-height: 100%;
  max-width: 1920px;
  margin: auto;
}
</style>
