<template>
  <section class="tests-list-page">
    <el-row justify="center" class="mb-2">
      <el-input
        class="filter"
        size="small"
        :placeholder="$t('filters.search')"
        v-model="search"
      />
    </el-row>

    <TestsTile :value="filteredTests" />
  </section>
</template>

<script>
import TestsTile from './components/TestsTile'

export default {
  name: 'TestsListPage',

  components: {
    TestsTile,
  },

  data() {
    return {
      isLoading: true,
      search: '',
      tests: [],
    }
  },

  computed: {
    filteredTests() {
      const q = this.search.toLowerCase()

      return !q
        ? this.tests
        : this.tests.filter(t => t.name.toLowerCase().includes(q))
    },
  },

  watch: {
    '$store.getters.locale': {
      immediate: true,
      handler: 'loadTests',
    },
  },

  methods: {
    async loadTests() {
      this.isLoading = true

      try {
        this.tests = await this.$store.dispatch('test/fetchTests')
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tests-list-page {
  display: flex;
  flex-direction: column;

  .filter {
    max-width: 600px;
  }
}
</style>
