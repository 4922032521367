<template>
  <el-table
    stripe
    border
    :data="value"
    height="100%"
    width="100%"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" :index="index" width="50" />

    <el-table-column type="selection" width="44" />

    <el-table-column
      sortable
      prop="name"
      :label="$t('tableLabels.name')"
      min-width="230"
      :sort-method="(a, b) => a.name.localeCompare(b.name)"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.name }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      sortable
      prop="city"
      :label="$t('tableLabels.city')"
      width="180"
      :sort-method="(a, b) => a.city.localeCompare(b.city)"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.city }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      prop="parentFullName"
      :label="$t('tableLabels.subordinateTo')"
      min-width="230"
    />

    <el-table-column
      sortable
      prop="admins"
      :label="$t('tableLabels.admins')"
      width="172"
      :sort-method="(a, b) => Number(a.admins) - Number(b.admins)"
    />

    <el-table-column
      sortable
      prop="users"
      :label="$t('tableLabels.users')"
      width="140"
      :sort-method="(a, b) => Number(a.users) - Number(b.users)"
    />

    <el-table-column
      prop="notes"
      :label="$t('tableLabels.notes')"
      min-width="140"
    />

    <el-table-column
      :label="$t('tableLabels.operations')"
      width="100"
    >
      <template slot-scope="{ row }">
        <el-tooltip :content="$t('buttons.edit')">
          <el-button
            v-if="!row.deletedAt"
            plain
            type="primary"
            icon="el-icon-edit"
            @click="$router.push({
              name: 'subdivision.edit',
              params: { id: row.id }
            })"
          />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import filteredTableMixin from '@/modules/core/mixins/filteredTableMixin'

export default {
  name: 'SubdivisionsTable',

  mixins: [filteredTableMixin],
}
</script>
