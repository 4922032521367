import i18n from '@/plugins/i18n'
import { requiredString, emailPattern } from './common'

export default {
  subdivisionId: [requiredString],
  email: [requiredString, emailPattern],
  password: [
    requiredString,
    {
      min: 9,
      max: 20,
      message: i18n.t('validation.core.minMax', { min: 9, max: 20 }),
      trigger: 'blur',
    },
  ],
  firstName: [requiredString],
  lastName: [requiredString],
}
