import { GET, POST, PUT } from '@/plugins/http'

export default {
  state: () => ({
    subdivisions: [],
  }),

  mutations: {
    setSubdivisions: (state, payload) => { state.subdivisions = payload },
  },

  actions: {
    async fetchAll(ctx, payload) {
      const { data } = await GET('/subdivisions', payload)

      return {
        total: Number(data.total),
        results: data.results,
      }
    },

    async fetchForFilter({ commit, getters }, options) {
      const ops = options || {}

      if (!ops.force && getters.subdivisions.length) {
        return getters.subdivisions
      }

      // TODO: fix this ugly route
      const { data } = await GET('/subdivisions/only/for/filter')

      commit('setSubdivisions', data)

      return getters.subdivisions
    },

    async get(ctx, { id }) {
      const { data } = await GET(`/subdivisions/${id}`)
      return data
    },

    async create(ctx, payload) {
      const { data } = await POST('/subdivisions', payload)
      return data
    },

    async update(ctx, payload) {
      const { data } = await PUT(`/subdivisions/${payload.id}`, payload)
      return data
    },

    async delete(ctx, payload) {
      const { data } = await POST('/subdivisions/delete', payload)
      return data
    },
    async getChildren(ctx, { id }) {
      const { data } = await GET(`/subdivisions/children/${id}`)
      return data
    },
  },

  getters: {
    subdivisions: state => state.subdivisions,
  },
}
