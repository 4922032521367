import { addDays } from '@/services/dateTime'
import { CurrentEvent } from './library'
import { cloneDeep } from './functions'

const userFilters = {
  search: '',
  position: null,
  currentEvent: null,
  testingAim: null,
  targetPosition: null,
  subdivision: null,
}

const register = {
  email: '',
  password: '',
}

const user = {
  email: '',
  firstName: '',
  lastName: '',
  // empty birthday only NULL, not a empty string!
  // ERROR: invalid input syntax for type timestamp with time zone: ""
  birthday: null,
  gender: '',
  subdivisionId: null,
  positionId: null,
  currentEventId: CurrentEvent.NOT_ASSIGNED,
  testingAimId: null,
  targetPositionId: null,
  meta: {},
  notes: '',
  oldPassword: '',
  newPasswordConfirm: '',
  password: '',
}

const subAdminFilters = {
  position: null,
  subdivision: null,
  status: null,
  search: '',
}

const subAdmin = {
  email: '',
  firstName: '',
  lastName: '',
  subdivisionId: null,
  positionId: null,
  isActive: false,
  notes: '',
}

const subdivision = {
  name: '',
  city: '',
  parentId: null,
}

const subdivisionFilters = {
  search: '',
  showDeleted: false,
  showChildren: false,
  currentId: null,
}

const activityStatuses = [
  { id: true, name: 'Active' },
  { id: false, name: 'Disabled' },
]

const assignResult = {
  userId: null,
  groupId: null,
  testingAimId: null,
  result: null,
  targetPositionId: null,
}

const assignForm = {
  testingAimId: null,
  selectedTests: [],
  dueDate: new Date(addDays(new Date(Date.now()), 1).setHours(12, 0, 0, 0)),
}

export const defaultEntities = {
  activityStatuses,
  subAdmin,
  subAdminFilters,
  subdivision,
  subdivisionFilters,
  register,
  user,
  userFilters,
  assignResult,
  assignForm,
}

export const getDefault = name => {
  const entity = defaultEntities[name]

  if (!entity) {
    console.error(`THERE IS NO DEFAULT ENTITY: ${name}`)
    return null
  }

  return cloneDeep(entity)
}
