<template>
  <div class="development-page text-center">
    <div class="mb-4 mt-4">
      <h1>{{ $t('core.development') }}</h1>
    </div>

    <router-link :to="$route.query.redirect || '/home'">
      {{ $t('buttons.back') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DevelopmentPage',
}
</script>
