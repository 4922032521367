<template>
  <div class="sub-admin-form">
    <el-row justify="center" class="mb-4">
      <h2>
        {{ $t(`form.name.${formData.id ? 'edit' : 'create'}`) }}
      </h2>
    </el-row>

    <el-form
      ref="form"
      label-width="200px"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="subdivisionId" :label="$t('form.subdivision.label')">
        <el-select filterable v-model="formData.subdivisionId">
          <el-option
            v-for="s in subdivisions"
            :key="s.id"
            :label="s.fullName"
            :value="s.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="email" :label="$t('form.email.label')">
        <el-input type="email" v-model="formData.email" />
      </el-form-item>

      <el-form-item prop="password" :label="$t('form.password.label')">
        <el-input
          show-password
          type="password"
          autocomplete="off"
          v-model="formData.password"
        ></el-input>
      </el-form-item>

      <el-form-item prop="lastName" :label="$t('form.lastName.label')">
        <el-input v-model="formData.lastName" />
      </el-form-item>

      <el-form-item prop="firstName" :label="$t('form.firstName.label')">
        <el-input v-model="formData.firstName" />
      </el-form-item>

      <el-form-item prop="positionId" :label="$t('form.position.label')">
        <el-select
          clearable
          filterable
          v-model="formData.positionId"
        >
          <el-option
            v-for="p in positions"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-switch
          style="display: block"
          v-model="formData.isActive"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-text="$t('buttons.enabled')"
          :inactive-text="$t('buttons.disabled')"
        />
      </el-form-item>

      <el-form-item prop="contacts" :label="$t('form.contacts.label')">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="formData.contacts"
        >
        </el-input>
      </el-form-item>

      <div class="actions">
        <router-link class="mr-1" :to="{ name: 'sub_admins' }">
          <el-button>{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          class="submit-btn"
          native-type="submit"
          :disabled="isEqual"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep, getValidationFor, isEqual } from '@/services'

export default {
  name: 'SubAdminForm',

  props: {
    subadmin: { type: Object, required: true },
  },

  data() {
    return {
      isEqual: true,
      formData: cloneDeep(this.subadmin),
      rules: getValidationFor(this, 'subadmin'),
    }
  },

  computed: {
    ...mapGetters({
      subdivisions: 'subdivision/subdivisions',
      positions: 'position/positions',
    }),
  },

  watch: {
    subadmin: {
      deep: true,
      handler(subadmin) {
        this.formData = cloneDeep(subadmin)
      },
    },
    formData: {
      deep: true,
      handler(newVal) {
        this.isEqual = isEqual(newVal, this.subadmin)
      },
    },
  },

  methods: {
    submit() {
      this.$refs.form.validate(isValid => {
        if (!isValid) return

        this.$emit('submit', this.formData)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/components/entity-form";
</style>
