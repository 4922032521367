var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assign-info-table"},[_c('el-table',{attrs:{"width":"100%","header-cell-style":{textAlign: 'center'},"data":_vm.value}},[_c('el-table-column',{attrs:{"type":"index","width":"44"}}),_c('el-table-column',{attrs:{"width":"160px","align":"center","label":_vm.$t('tableLabels.assignDate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDate(row.createdAt))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"200px","align":"center","label":_vm.$t('tableLabels.testing_aim')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.aimsNames[row.testingAimId])+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"64px","align":"center","label":_vm.$t('tableLabels.testName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.test ? row.test.name : '-')+" ")]}}])}),_c('el-table-column',{attrs:{"width":"102px","align":"center","label":_vm.$t('tableLabels.dueDate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDate(row.dueDate))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"106px","align":"center","label":_vm.$t('tableLabels.started')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDate(row.startedAt))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"200px","align":"center","label":_vm.$t('tableLabels.testingDuration')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formattedTime(row.testDuration))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"150px","label":_vm.$t('tableLabels.status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[_c('el-button',{staticClass:"disable-event status-btn",attrs:{"plain":"","type":_vm.EVENT_STATUSES[row.status]},on:{"click":function($event){return _vm.$emit('show-assignment', row)}}},[_vm._v(" "+_vm._s(_vm.assignmentStatuses[row.status])+" ")]),_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.reassign')}},[(_vm.isCanReAssign(row))?_c('div',{staticClass:"icon__wrapper"},[_c('i',{staticClass:"icon el-icon-refresh-left enable-event",on:{"click":function($event){return _vm.$emit('reassign', row)}}})]):_vm._e()])],1)]}}])}),_c('el-table-column',{attrs:{"width":"100px","label":_vm.$t('tableLabels.actions')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button-group',{attrs:{"size":"mini"}},[_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.viewReport')}},[_c('el-button',{staticClass:"option",attrs:{"plain":"","icon":"el-icon-view","disabled":!_vm.isDone(row)},on:{"click":function($event){return _vm.$emit('test:use-options', row)}}})],1),_c('el-tooltip',{attrs:{"content":_vm.$t('buttons.sendReport')}},[_c('el-button',{staticClass:"option option--send-report",attrs:{"plain":"","icon":"el-icon-message","disabled":!_vm.isDone(row)},on:{"click":function($event){return _vm.sendReport(row)}}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"210px","label":_vm.$t('tableLabels.notes')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TextEditInput',{staticClass:"w-100",attrs:{"value":row.notes},on:{"save":function($event){return _vm.$emit('row:update', {
            formData: { notes: $event },
            originData: row
          })}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }