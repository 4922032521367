<template>
  <div class="user-form">
    <el-row justify="center" class="mb-4">
      <h2>{{ $t(`form.name.${formData.id ? 'edit' : 'create'}`) }}</h2>
    </el-row>

    <el-form
      ref="form"
      label-width="200px"
      :rules="rules"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-form-item prop="email" :label="$t('form.email.label')">
        <el-input type="email" v-model="formData.email" />
      </el-form-item>

      <el-form-item prop="lastName" :label="$t('form.lastName.label')">
        <el-input v-model="formData.lastName" />
      </el-form-item>

      <el-form-item prop="firstName" :label="$t('form.firstName.label')">
        <el-input v-model="formData.firstName" />
      </el-form-item>

      <el-form-item prop="birthday" :label="$t('form.birthday.label')">
        <el-date-picker
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="formData.birthday"
          :default-value="defaultDate"
        />
      </el-form-item>

      <el-form-item prop="gender" :label="$t('form.gender.label')">
        <el-radio-group v-model="formData.gender">
          <el-radio label="male">{{ $t('form.gender.male') }}</el-radio>
          <el-radio label="female">{{ $t('form.gender.female') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item prop="subdivisionId" :label="$t('form.subdivision.label')">
        <el-select filterable v-model="formData.subdivisionId">
          <el-option
            v-for="s in subdivisions"
            :key="s.id"
            :label="s.fullName"
            :value="s.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="positionId" :label="$t('form.position.label')">
        <el-select filterable v-model="formData.positionId">
          <el-option
            v-for="p in positions"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="testingAimId" :label="$t('form.testingAim.label')">
        <el-select
          filterable
          disabled
          v-model="formData.testingAimId"
        >
          <el-option
            v-for="ta in testingAims"
            :key="ta.id"
            :label="ta.name"
            :value="ta.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        prop="targetPositionId"
        :label="$t('form.targetPosition.label')"
      >
        <el-select filterable v-model="formData.targetPositionId">
          <el-option
            v-for="tp in positions"
            :key="tp.id"
            :label="tp.name"
            :value="tp.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="notes" :label="$t('form.notes.label')">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="formData.notes"
        >
        </el-input>
      </el-form-item>

      <div class="actions">
        <router-link class="mr-1" :to="{ name: 'users' }">
          <el-button>{{ $t('buttons.cancel') }}</el-button>
        </router-link>

        <el-button
          plain
          type="primary"
          native-type="submit"
          class="submit-btn"
          :disabled="isEqual"
        >
          {{ $t('buttons.save') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep, isEqual, getValidationFor } from '@/services'

export default {
  name: 'UserForm',

  props: {
    user: { type: Object, required: true },
  },

  data() {
    return {
      formData: cloneDeep(this.user),
      isEqual: true,
      defaultDate: new Date(Date.now()),
      rules: getValidationFor(this, 'user'),
    }
  },

  computed: {
    ...mapGetters({
      subdivisions: 'subdivision/subdivisions',
      positions: 'position/positions',
      currentEvents: 'currentEvent/currentEvents',
      testingAims: 'testingAim/testingAims',
    }),
  },

  watch: {
    user: {
      deep: true,
      handler(user) {
        this.formData = cloneDeep(user)
      },
    },
    formData: {
      deep: true,
      handler(newVal) {
        this.isEqual = isEqual(newVal, this.user)
      },
    },
  },

  methods: {
    submit() {
      this.$refs.form.validate(isValid => {
        if (!isValid) return false

        this.$emit('submit', this.formData)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/components/entity-form";
</style>
