<template>
  <div class="tests-tile">
    <ul>
      <li v-for="test in value" :key="test.id">
        <TestCard :value="test" @show-info="showInfo" />
      </li>
    </ul>
  </div>
</template>

<script>
import TestCard from './TestCard'

export default {
  name: 'TestsTile',

  components: { TestCard },

  props: {
    value: { type: Array, required: true },
  },

  methods: {
    showInfo({ id }) {
      return this.$router.push({ name: 'test', params: { id } })
    },
  },
}
</script>

<style scoped lang="scss">
.tests-tile {
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  li {
    width: 300px;
    margin: 10px;
  }
}
</style>
