import { GET, POST, PUT } from '@/plugins/http'

export default {
  actions: {
    // TODO getAll
    async fetchAll(ctx, payload) {
      const { data } = await GET('/users', payload)

      return {
        total: Number(data.total),
        results: data.results,
      }
    },

    async get(ctx, { id }) {
      const { data } = await GET(`/users/${id}`)
      return data
    },

    async create(ctx, payload) {
      const { data } = await POST('/users', payload)
      return data
    },

    async update(ctx, payload) {
      const { data } = await PUT(`/users/${payload.id}`, payload)
      return data
    },

    async delete(ctx, payload) {
      const { data } = await POST('/users/delete', payload)
      return data
    },
  },
}
