<template>
  <section class="subdivisions-page">
    <el-row class="mb-04" justify="space-between" align="middle">
      <div>
        <h1>{{ $t(`routes.${$route.name}`) }}</h1>
      </div>

      <div class="ml-1">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({ name: 'subdivision.new' })"
          >
            {{ $t('buttons.add') }}
          </el-button>

          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="handleDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <SubdivisionFilters
      class="mb-04"
      :value="filters"
      v-bind.sync="filters"
      @clear-filters="clearFilters"
    />

    <div class="content">
      <SubdivisionsTable
        v-loading="isLoading"
        :index="index"
        :value="tableData"
        :selected="selected"
        :search-query="filters.search"
        @selection-change="selected = $event"
        @toggle-activation="toggleActivation"
      />
    </div>

    <el-row justify="space-between" class="mt-1">
      <div>
        <span v-if="total">{{ $t('tableLabels.total') }}: {{ total }}</span>
      </div>

      <div>
        <el-pagination
          hide-on-single-page
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="page"
          :page-size="PAGINATION_SIZE"
          @current-change="showCurrentPage"
        />
      </div>

      <div />
    </el-row>
  </section>
</template>

<script>
import {
  notify,
  head,
  pluck,
  getIndex,
  debounce,
  getDefault,
  env,
} from '@/services'
import SubdivisionFilters from './components/SubdivisionFilters'
import SubdivisionsTable from './components/SubdivisionsTable'

export default {
  name: 'SubdivisionsPage',

  components: {
    SubdivisionFilters,
    SubdivisionsTable,
  },

  data() {
    return {
      isLoading: true,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
      page: 1,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.applyFilters()
        }
      },
    },
    selected: {
      deep: true,
      handler() {
        if (!this.isLoading && this.filters.showChildren) {
          if (this.selected.length === 1) {
            return this.applyFilters()
          }

          notify({
            type: 'warning',
            title: this.$t('filters.showChildren'),
            message: this.$t('messages.showChildren'),
          })
        }
      },
    },
  },

  mounted() {
    this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
    this.load()
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch(
          'filters/get',
          { key: 'subdivisionFilters' },
        )

        if (!filters) return null

        this.$set(this, 'filters', filters)

        return filters
      } catch (e) {
        console.error(e)
      }
    },

    async storeFilters() {
      try {
        await this.$store.dispatch('filters/set', {
          key: 'subdivisionFilters',
          value: this.filters,
        })
      } catch (e) {
        console.error(e)
      }
    },

    async load() {
      this.isLoading = true

      try {
        const filters = await this.restoreFilters()

        if (!filters) return null

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters,
        }

        const { total, results } = await this.$store.dispatch(
          'subdivision/fetchAll',
          { params },
        )

        this.total = total
        this.tableData = results
        return results
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async applyFilters() {
      this.isLoading = true

      try {
        this.page = 1

        if (this.filters.showChildren) {
          if (this.selected.length !== 1) {
            return notify({
              type: 'warning',
              title: this.$t('filters.showChildren'),
              message: this.$t('messages.showChildren'),
            })
          }

          this.filters.currentId = head(this.selected).id
        }

        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true

      try {
        this.filters = getDefault('subdivisionFilters')
        this.page = 1
        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      this.isLoading = true

      try {
        this.page = page
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async toggleActivation({ id, isActive }) {
      try {
        const payload = { id, isActive: !isActive }
        await this.$store.dispatch('auth/update', payload)

        await this.load()

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$tc(
            'messages.adminActivationSuccess',
            payload.isActive ? 1 : 2,
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async isUsed(selected) {
      const list = selected.map(({ id }) => this.$store.dispatch(
        'subdivision/getChildren',
        { id },
      ))

      const children = await Promise.all(list)

      const hasAdminsOrUsers = selected.some(
        s => Number(s.admins) !== 0 || Number(s.users) !== 0,
      )

      const hasChildren = children.some(c => c.length !== 0)

      if (hasAdminsOrUsers) {
        notify({
          type: 'warning',
          title: this.$t('messages.subdivisionInNotEmptyTitle'),
          message: this.$t('messages.subdivisionInNotEmptyDescription'),
        }, true)
      }

      if (hasChildren) {
        setTimeout(() => {
          notify({
            type: 'warning',
            title: this.$t('messages.subdivisionInNotEmptyTitle'),
            message: this
              .$t('messages.subdivisionChildrenInNotEmptyDescription'),
          }, true)
        }, 300)
      }

      return hasAdminsOrUsers || hasChildren
    },

    async handleDelete() {
      const isUsed = await this.isUsed(this.selected)

      if (isUsed) return false
      this.isLoading = true

      try {
        const deletedIds = await this.$store.dispatch(
          'subdivision/delete',
          { ids: pluck(this.selected, 'id') },
        )

        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        return notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            'messages.subdivisionRemoved',
            { num: deletedIds.length },
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.subdivisions-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
