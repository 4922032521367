<template>
  <div class="report-info-options" @keydown.enter="submit">
    <TheLoader v-if="isLoading" />

    <el-form
      v-else
      ref="form"
      :model="formData"
      @submit.native.prevent="submit"
    >
      <el-row justify="center" v-if="!testOption">
        {{ $t('el.table.emptyText') }}
      </el-row>

      <el-row v-else class="mb-2">
        <el-form-item
          required
          class="mr-1"
          prop="reportPackage"
          :label="$t('reports.reportType')"
        >
          <el-select
            :placeholder="$t('reports.reportType')"
            v-model="formData.reportPackage"
          >
            <el-option
              v-for="item in reportPackages"
              :key="item.name"
              :label="$t(`reportTypes.${item.name}`)"
              :value="item.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          required
          class="mr-1"
          prop="detailing"
          :label="$t('reports.detailing')"
        >
          <el-select
            :placeholder="$t('reports.detailing')"
            v-model="formData.detailing"
          >
            <el-option
              v-for="item in detailing"
              :key="item.name"
              :label="$t(`reportDetailing.${item.name}`)"
              :value="item.name"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          required
          class="mr-1"
          prop="language"
          :label="$t('reports.language')"
        >
          <el-select

            :placeholder="$t('reports.language')"
            :disabled="!formData.detailing"
            v-model="formData.language"
          >
            <el-option
              v-for="item in translations"
              :key="item"
              :label="item.toUpperCase()"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-row>

      <el-row justify="end">
        <el-button @click="$emit('close')">
          {{ $t('buttons.back') }}
        </el-button>
        <el-button
          :disabled="!Object.values(formData).every(Boolean)"
          type="primary"
          native-type="submit"
        >
          {{ submitBtnTitle }}
        </el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { notify } from '@/services'

const DEFAULT_OPTION_NAME = 'standard'

export default {
  name: 'ReportInfoOptions',

  props: {
    blank: Boolean,
    value: Object,
    report: { type: Object, required: true },
  },

  data: () => ({
    isLoading: true,
    formData: {
      reportPackage: null,
      detailing: null,
      language: null,
    },
  }),

  computed: {
    testOption() {
      const list = this.$store.getters['reports/testsInfo']
      return list.find(({ id }) => id === this.report.test.id) || null
    },

    reportPackages() {
      return this.testOption?.reportPackages || []
    },

    detailing() {
      return this.testOption
        ?.reportPackages
        ?.find(it => it.name === this.formData.reportPackage)
        ?.detailing || []
    },

    translations() {
      return this.detailing
        ?.find(({ name }) => name === this.formData.detailing)
        ?.translations || []
    },

    submitBtnTitle() {
      return this.report?.isAction === 'test:send-report'
        ? this.$t('buttons.send')
        : this.$t('buttons.view')
    },
  },

  async created() {
    await this.$store.dispatch('reports/fetchTestsInfo')
    this.setDefaultData()
    this.isLoading = false
  },

  methods: {
    setDefaultData() {
      if (!this.testOption) {
        return null
      }

      const { testOption, value } = this
      const { reportPackage, detailing, language } = value || {}

      const defaultPackage = testOption.reportPackages
        .find(it => it.name === DEFAULT_OPTION_NAME)
        || testOption.reportPackages[0]

      this.formData.reportPackage = reportPackage
        || defaultPackage?.name
        || null

      const defaultDetailing = defaultPackage?.detailing[0] || null

      this.formData.detailing = detailing
        || defaultDetailing?.name
        || null

      this.formData.language = language
        || defaultDetailing?.translations[0]
        || null
    },

    submit() {
      this.$refs.form.validate(async isValid => {
        if (!isValid) {
          return false
        }

        if (this.report.isAction) {
          return this.useReportAction()
        } if (this.$route.name === 'reports.assignment') {
          await this.$router.push({ query: this.formData })
          this.$emit('update', this.formData)
        } else {
          await this.openReportPDF()
        }

        this.$emit('close')
      })
    },

    useReportAction() {
      notify({
        type: 'success',
        title: this.$t('reports.reportSubmitted'),
        message: this.$t(
          'reports.reportSubmittedMessage',
          { name: this.testOption.name },
        ),
      })

      return this.$emit(
        this.report.isAction,
        {
          report: this.report,
          formData: this.formData,
        },
      )
    },

    async openReportPDF() {
      const data = await this.$store.dispatch('reports/getAssignment', {
        id: this.report.id,
        query: this.formData,
      })

      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(file)
      window.open(fileURL)

      // TODO: https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
      setTimeout(() => window.URL.revokeObjectURL(data), 100)
    },
  },
}
</script>
