import axios from 'axios'
import {
  errNotify, getTimeZone, env, eventBus,
} from '@/services'
import { routes } from './routes'

axios.defaults.baseURL = env.baseURL
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-timezone'] = getTimeZone()

// TODO write within circular dependency
// axios.interceptors.request.use(request => {
//   request.headers['X-locale'] = store.getters.locale
//   return request
// })

axios.interceptors.response.use(
  response => response,
  async error => {
    if (!error.response) {
      return Promise.reject(error)
    }

    const { status } = error.response

    if (status === 401) {
      const pathname = window?.location?.pathname || ''
      const route = routes.find(item => item.path === pathname)

      if (
        pathname
        && route?.meta?.secure
        && route.path !== '/login'
      ) {
        if (window?.isAppLoaded) {
          eventBus.$emit(
            'isLoginPopup',
            {
              value: true,
              message: error.response?.data?.message || '',
            },
          )
        } else {
          eventBus.$emit('router:push', { name: 'login' })
        }
      } else if (route?.meta?.secure) {
        errNotify(error, { type: 'warning' })
      }
    } else if (![404].includes(status)) {
      errNotify(error)
    }

    return Promise.reject(error)
  },
)

export const http = axios
export const GET = (url, options) => axios.get(url, options)
export const POST = (url, data, options) => axios.post(url, data, options)
export const PUT = (url, data, options) => axios.put(url, data, options)
export const DELETE = (url, options) => axios.delete(url, options)
