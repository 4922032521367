<template>
  <div @click.stop class="locale-switcher">
    <el-select
      class="short"
      size="medium"
      :value="$i18n.locale"
      @change="setLocale"
    >
      <template #prefix>
        {{ $i18n.locale.toUpperCase() }}
      </template>
      <el-option
        v-for="locale in locales"
        :label="locale.label"
        :value="locale.value"
        :key="locale.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',

  data() {
    return {
      isShowMenu: false,
      locales: this.$i18n.availableLocales
        .map(value => ({ value, label: this.$i18n.messages[value].langName })),
    }
  },

  methods: {
    setLocale(locale) {
      return this.$store.dispatch('setLocale', { locale })
    },
  },
}
</script>

<style lang="scss" scoped>
.locale-switcher {
  .el-select {
    height: 35.5px;
    width: fit-content;
    cursor: pointer;
  }

  ::v-deep {
    .el-input {
      width: 100%;
    }

    .el-input__prefix {
      color: $--color-text-regular;
      padding: 10px 8px;
    }

    .el-input__inner {
      width: 0;
    }

    .el-input__suffix {
      right: 5px;
    }
  }
}
</style>
