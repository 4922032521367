<template>
  <router-link :to="{ name: 'home' }" class="the-logo">
    <img src="@/assets/icon/logo.svg" :alt="$t('core.appDescription')" />
  </router-link>
</template>

<script>
export default {
  name: 'TheLogo',
}
</script>

<style scoped>
.the-logo img {
  display: block;
}
</style>
