import i18n from '../../plugins/i18n'
import { requiredString, emailPattern } from './common'

export default {
  email: [requiredString, emailPattern],
  password: [
    requiredString,
    {
      min: 8,
      max: 20,
      message: i18n.t('validation.core.minMax', { min: 8, max: 20 }),
      trigger: 'blur',
    },
  ],
}
