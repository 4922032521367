<template>
  <el-card class="test-card" shadow="hover">
    <div slot="header">
      <div
        class="header"
        :style="background"
        @click="$emit('show-info', value)"
      >
        <span class="title" v-if="!value.image">
          {{ value.title }}
        </span>
      </div>
    </div>

    <div class="description mb-2">
      <el-row justify="center" class="label">
        <h3>{{ value.title }}</h3>
      </el-row>
      <div class="description">
        <p>{{ value.description }}</p>
      </div>
    </div>

    <el-row justify="space-around" class="info">
      <div v-for="i in ['questionsCount', 'timeLimit']" :key="i">
        <div>{{ $t(`tests.${i}`) }}</div>
        <div>{{ value[i] }}</div>
      </div>
    </el-row>
  </el-card>
</template>

<script>
import { env } from '@/services'

export default {
  name: 'TestCard',

  props: {
    value: { type: Object, required: true },
  },

  computed: {
    background() {
      if (this.value.image) {
        const url = `${env.FS_BASE_URL}/${this.value.image}`
        return `background-image: url(${url});`
      }

      return 'background-color: #E6A23C'
    },
  },
}
</script>

<style scoped lang="scss">
.test-card {
  border: 2px solid $grey-300;
  overflow: visible;

  ::v-deep .el-card__header {
    padding: 0;
    margin: -2px;
    overflow: hidden;
    border-radius: 5px;
    font-size: 2rem;

    .header {
      display: flex;
      height: 150px;
      padding: 20px;
      text-shadow: 0 0 7px #000000;
      background-position: center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      cursor: pointer;

      .title {
        color: $white;
        margin: auto;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  ::v-deep .el-card__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .info > div {
    text-align: center;
    font-size: .8rem;
  }

  .label {
    min-height: 50px;
  }

  .description {
    height: 160px;
    overflow: hidden;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
