<template>
  <div class="assign-info-form">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <AssignInfoTable
        class="mb-2"
        :value="tableData"
        :readonly="readonly"
        v-loading="isReassignLoading"
        @reassign="reassign"
        @test:use-options="$emit('test:use-options', $event)"
        @row:update="update"
      />

      <template v-if="tableData.length">
        <el-select
          class="mr-2"
          clearable
          filterable
          :disabled="readonly"
          :placeholder="$t('tableLabels.targetPosition')"
          v-model="formData.targetPositionId"
        >
          <el-option
            v-for="tp in positions"
            :key="tp.id"
            :label="tp.name"
            :value="tp.id"
          />
        </el-select>

        <el-select
          clearable
          filterable
          :disabled="readonly || !Number(formData.targetPositionId)"
          :placeholder="$t('filters.decide')"
          v-model="formData.result"
        >
          <el-option
            v-for="tp in currentEvents"
            :key="tp.id"
            :label="tp.name"
            :value="tp.id"
          />
        </el-select>
      </template>

      <el-row justify="end">
        <el-button v-if="!hideCancel" @click="$emit('close')">
          {{ $t('buttons.cancel') }}
        </el-button>

        <el-button
          v-if="!readonly"
          plain
          type="primary"
          :disabled="!Number(formData.result)"
          @click="apply"
        >
          {{ $t('buttons.apply') }}
        </el-button>
      </el-row>
    </template>
  </div>
</template>

<script>
import { CurrentEvent, getDefault } from '@/services'
import AssignInfoTable from './AssignInfoTable'

export default {
  name: 'AssignInfoForm',

  components: {
    AssignInfoTable,
  },

  props: {
    hideCancel: Boolean,
    value: { type: Object, required: true },
  },

  data() {
    return {
      isLoading: true,
      isReassignLoading: false,
      tableData: [],
      formData: getDefault('assignResult'),
    }
  },

  computed: {
    readonly() {
      return [CurrentEvent.NOT_RECOMMEND, CurrentEvent.RECOMMEND]
        .includes(this.value.currentEventId)
    },

    currentEvents() {
      const { NOT_RECOMMEND, RECOMMEND } = CurrentEvent

      return [
        { id: NOT_RECOMMEND, name: this.$t('currentEvents.not_recommend') },
        { id: RECOMMEND, name: this.$t('currentEvents.recommend') },
      ]
    },

    positions() {
      return this.$store.getters['position/positions']
    },
  },

  async mounted() {
    this.loadDependency()
    await this.load()
  },

  methods: {
    loadDependency() {
      return this.$store.dispatch('position/fetchForFilter')
    },

    async load() {
      try {
        const { result, group } = await this.$store.dispatch(
          'assignment/getGroup',
          { groupId: this.value.meta.groupId },
        )

        this.tableData = group

        if (result) {
          this.formData = result
        }
      } catch (e) {
        console.error(e)
        this.$emit('close')
      } finally {
        this.isLoading = false
      }
    },

    async apply() {
      if (this.formData.result) {
        this.formData.userId = this.value.id
        this.formData.groupId = this.value.meta.groupId
        this.formData.testingAimId = this.tableData[0].testingAimId

        await this.submitAimResult(this.formData)

        this.$emit('update-data', this.tableData)
        this.$emit('close')
      }
    },

    async reassign({ id }) {
      this.isReassignLoading = true

      try {
        const data = await this.$store.dispatch(
          'assignment/reassign',
          { id },
        )

        const index = this.tableData.findIndex(item => item.id === id)

        if (index > -1) {
          this.$set(this.tableData[index], 'status', data.status)
          this.$emit('update-data', this.tableData)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isReassignLoading = false
      }
    },

    async submitAimResult(formData) {
      try {
        return await this.$store.dispatch(
          'assignment/setAimResult',
          formData,
        )
      } catch (e) {
        console.error(e)
      }
    },

    async update({ formData, originData }) {
      try {
        const newData = await this.$store.dispatch(
          'assignment/edit',
          {
            id: originData.id,
            formData,
          },
        )

        this.updateRow(originData, newData)
      } catch (e) {
        console.error(e)
      }
    },

    updateRow({ id }, data) {
      const index = this.tableData.findIndex(row => row.id === id)

      if (index > -1) {
        this.$set(
          this.tableData,
          index,
          { ...this.tableData[index], ...data },
        )
      }
    },
  },
}
</script>
