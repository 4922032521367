<template>
  <section class="sub-admin-page entity-edit-page">
    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="page-title" v-if="subadmin">
        <h1>{{ subadmin.lastName }} {{ subadmin.firstName }}</h1>
      </div>

      <el-tabs v-model="tab" type="border-card">
        <el-tab-pane name="info">
          <span slot="label">
            <i class="el-icon-notebook-2" /> {{ $t('user.profile') }}
          </span>

          <SubAdminForm
            class="form"
            :subadmin="subadmin"
            v-loading="isVLoading"
            @submit="submit"
          />
        </el-tab-pane>

        <el-tab-pane name="history" v-if="subadmin.id" lazy>
          <span slot="label">
            <i class="el-icon-time" />
            {{ $t('user.testingHistory') }}
          </span>

          Actions history
        </el-tab-pane>
      </el-tabs>
    </template>
  </section>
</template>

<script>
import { notify, getDefault } from '@/services'
import SubAdminForm from './components/SubAdminForm'

export default {
  name: 'SubAdminPage',

  components: {
    SubAdminForm,
  },

  data() {
    return {
      isLoading: true,
      isVLoading: false,
      subadmin: null,
    }
  },

  computed: {
    tab: {
      get() {
        return this.$route.query.tab || 'info'
      },
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
    },
  },

  created() {
    this.$store.dispatch('subdivision/fetchForFilter')
    this.load()
  },

  methods: {
    async load() {
      this.isLoading = true
      const { id } = this.$route.params

      try {
        this.subadmin = this.$route.params.id
          ? await this.$store.dispatch('subAdmin/get', { id })
          : getDefault('subAdmin')
      } catch (e) {
        console.error(e)
        this.subadmin = getDefault('subAdmin')
        return this.$router.push({ name: 'sub_admins' })
      } finally {
        this.isLoading = false
      }
    },

    async submit(formData) {
      this.isVLoading = true

      try {
        // TODO: implements update only changed fields
        this.subadmin = await this.$store.dispatch(
          `subAdmin/${formData.id ? 'update' : 'create'}`,
          formData,
        )

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            `messages.user${formData.id ? 'Updated' : 'Created'}Success`,
            { name: `${this.subadmin.lastName} ${this.subadmin.firstName}` },
          ),
        }, true)

        return await this.$router.push({ name: 'sub_admins' })
      } catch (e) {
        console.error(e)
      } finally {
        this.isVLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/components/entity-edit-page.scss";
</style>
