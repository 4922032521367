<template>
  <section class="sub-admins-page">
    <el-row class="mb-04" justify="space-between" align="middle">
      <div>
        <h1>{{ $t(`routes.${$route.name}`) }}</h1>
      </div>

      <div class="ml-1">
        <el-button-group>
          <el-button
            plain
            type="success"
            icon="el-icon-plus"
            @click="$router.push({ name: 'sub_admin.new' })"
          >
            {{ $t('buttons.add') }}
          </el-button>

          <el-button
            plain
            type="danger"
            icon="el-icon-delete"
            :disabled="!selected.length"
            @click="handleDelete"
          >
            {{ $t('buttons.delete') }}
          </el-button>
        </el-button-group>
      </div>
    </el-row>

    <SubAdminFilters
      class="mb-04"
      :value="filters"
      v-bind.sync="filters"
      @clear-filters="clearFilters"
    />

    <div class="content">
      <SubAdminsTable
        v-loading="isLoading"
        :index="index"
        :value="tableData"
        :selected="selected"
        :search-query="filters.search"
        @selection-change="selected = $event"
        @toggle-activation="toggleActivation"
      />
    </div>

    <el-row justify="space-between" class="mt-1">
      <div>
        <span v-if="total">{{ $t('tableLabels.total') }}: {{ total }}</span>
      </div>

      <div>
        <el-pagination
          hide-on-single-page
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="page"
          :page-size="PAGINATION_SIZE"
          @current-change="showCurrentPage"
        />
      </div>

      <div />
    </el-row>
  </section>
</template>

<script>
import {
  notify,
  pluck,
  getIndex,
  debounce,
  env,
  getDefault,
} from '@/services'
import SubAdminFilters from './components/SubAdminFilters'
import SubAdminsTable from './components/SubAdminsTable'

export default {
  name: 'SubAdminsPage',

  components: {
    SubAdminFilters,
    SubAdminsTable,
  },

  data() {
    return {
      isLoading: true,
      filters: {},
      tableData: [],
      selected: [],
      total: 0,
      PAGINATION_SIZE: env.PAGINATION_SIZE,
      page: 1,
    }
  },

  computed: {
    index() {
      return getIndex(this.page)
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.applyFilters()
        }
      },
    },
  },

  async mounted() {
    try {
      await this.$store.dispatch('subdivision/fetchForFilter')
      this.applyFilters = debounce(this.applyFilters, env.DEBOUNCE_TIME)
      await this.load()
    } catch (e) {
      console.error(e)
    }
  },

  methods: {
    async restoreFilters() {
      try {
        const filters = await this.$store.dispatch(
          'filters/get',
          { key: 'subAdminFilters' },
        )

        if (!filters) return null

        this.$set(this, 'filters', filters)

        return filters
      } catch (e) {
        console.error(e)
      }
    },

    async storeFilters() {
      try {
        return await this.$store.dispatch('filters/set', {
          key: 'subAdminFilters',
          value: this.filters,
        })
      } catch (e) {
        console.error(e)
      }
    },

    loadDependencies() {
      return this.$store.dispatch(
        'dependency/fetchForFilter',
        ['subdivision', 'position'],
      )
    },

    async load() {
      this.isLoading = true

      try {
        const filters = await this.restoreFilters()

        if (!filters) return null

        await this.loadDependencies()

        const params = {
          limit: this.PAGINATION_SIZE,
          offset: (this.page - 1) * this.PAGINATION_SIZE,
          filters,
        }

        const { total, results } = await this.$store.dispatch(
          'subAdmin/fetchAll',
          { params },
        )

        this.total = total
        this.tableData = results
        return results
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async applyFilters() {
      this.isLoading = true

      try {
        this.page = 1

        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async clearFilters() {
      this.isLoading = true

      try {
        this.filters = getDefault('subAdminFilters')
        this.page = 1
        await this.storeFilters()
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async showCurrentPage(page) {
      this.isLoading = true

      try {
        this.page = page
        return await this.load()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async toggleActivation({ id, isActive }) {
      this.isLoading = true

      try {
        const payload = { id, isActive: !isActive }
        await this.$store.dispatch('subAdmin/update', payload)

        await this.load()

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$tc(
            'messages.adminActivationSuccess',
            payload.isActive ? 1 : 2,
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async handleDelete() {
      this.isLoading = true

      try {
        const deletedIds = await this.$store.dispatch(
          'subAdmin/delete',
          { ids: pluck(this.selected, 'id') },
        )

        this.tableData = this.tableData.filter(d => !deletedIds.includes(d.id))

        notify({
          type: 'success',
          title: this.$t('messages.success'),
          message: this.$t(
            'messages.adminsRemoved',
            { num: deletedIds.length },
          ),
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-admins-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
  }
}
</style>
