import {
  SolutionsPage,
  AboutPage,
  TPage,
  DevelopmentPage,
  ErrorPage,
} from '@/modules/core'
import { LoginPage } from '@/modules/auth'
import { TestsListPage, TestPage } from '@/modules/tests'
import { UsersPage, UserPage } from '@/modules/users'
import { SubAdminsPage, SubAdminPage } from '@/modules/subAdmins'
import { SubdivisionsPage, SubdivisionPage } from '@/modules/subdivisions'

export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/users',
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
    meta: { secure: true },
  },
  {
    path: '/users/new',
    name: 'user.new',
    component: UserPage,
    meta: { secure: true },
  },
  {
    path: '/users/:id',
    name: 'user.edit',
    component: UserPage,
    meta: { secure: true },
  },
  {
    path: '/subAdmins',
    name: 'sub_admins',
    component: SubAdminsPage,
    meta: { secure: true },
  },
  {
    path: '/subAdmins/new',
    name: 'sub_admin.new',
    component: SubAdminPage,
    meta: { secure: true },
  },
  {
    path: '/subAdmins/:id',
    name: 'sub_admin.edit',
    component: SubAdminPage,
    meta: { secure: true },
  },
  {
    path: '/subdivisions',
    name: 'subdivisions',
    component: SubdivisionsPage,
    meta: { secure: true },
  },
  {
    path: '/subdivisions/new',
    name: 'subdivision.new',
    component: SubdivisionPage,
    meta: { secure: true },
  },
  {
    path: '/subdivisions/:id',
    name: 'subdivision.edit',
    component: SubdivisionPage,
    meta: { secure: true },
  },
  {
    path: '/tests',
    name: 'tests',
    component: TestsListPage,
  },
  {
    path: '/tests/:id',
    name: 'test',
    component: TestPage,
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: SolutionsPage,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/dev',
    name: 'dev',
    component: DevelopmentPage,
  },
  {
    path: '/ttest',
    name: 'ttest',
    component: TPage,
  },
  {
    path: '/error',
    name: 'error',
    props: true,
    component: ErrorPage,
  },
  { path: '*', redirect: { name: 'home' } },
]
