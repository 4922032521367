import { http, GET } from '@/plugins/http'

export default {
  actions: {
    // TODO getTests
    async fetchTests({ rootGetters }) {
      const options = {
        headers: { 'X-locale': rootGetters.locale },
      }

      const { data } = await GET('/tests', options)
      return data
    },

    // TODO getTestsForAssignments
    async fetchTestsForAssignments({ rootGetters }) {
      const options = {
        headers: { 'X-locale': rootGetters.locale },
      }

      const { data } = await GET('/tests/for-assignments', options)
      return data
    },

    async get({ rootGetters }, { id }) {
      const options = {
        headers: { 'X-locale': rootGetters.locale },
      }

      const { data } = await GET(`/tests/test/${id}`, options)
      return data
    },

    async getLayout({ rootGetters }, { id }) {
      const { data } = await http({
        method: 'get',
        baseURL: `/testLayouts/${id}_${rootGetters.locale}.html`,
        headers: { 'X-locale': rootGetters.locale },
      })

      return data
    },
  },
}
