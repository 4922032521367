import i18n from '../../plugins/i18n'

export const requiredString = {
  required: true,
  message: i18n.t('validation.core.required'),
  trigger: 'blur',
}

export const emailPattern = {
  type: 'email',
  message: i18n.t('validation.email.pattern'),
  trigger: 'blur',
}
