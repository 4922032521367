<template>
  <div class="text-edit-input">
    <el-input
      class="mr-1"
      type="textarea"
      rows="1"
      v-model="formData"
    />

    <div class="save-btn">
      <el-tooltip :content="$t('buttons.save')">
        <el-button
          v-show="!isEqual"
          size="mini"
          circle
          type="success"
          icon="el-icon-check"
          @click="$emit('save', formData)"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextEditInput',

  props: {
    value: String,
  },

  data: () => ({ formData: '' }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.formData = this.value || ''
      },
    },
  },

  computed: {
    isEqual() {
      return this.formData === (this.value || '')
    },
  },
}
</script>

<style scoped lang="scss">
.text-edit-input {
  display: flex;
  align-items: center;

  .save-btn {
    width: 30px;
  }
}
</style>
