import i18n from '@/plugins/i18n'
import { requiredString, emailPattern } from './common'

const minMaxPass = {
  min: 8,
  max: 20,
  message: i18n.t('validation.core.minMax', { min: 8, max: 20 }),
  trigger: 'blur',
}

export default {
  email: [requiredString, emailPattern],
  firstName: [requiredString],
  lastName: [requiredString],
  birthday: [requiredString],
  gender: [requiredString],
  subdivisionId: [requiredString],
  oldPassword: [
    { validator: 'oldPasswordValidator', trigger: 'blur' },
    minMaxPass,
  ],
  newPassword: [
    { validator: 'newPasswordValidator', trigger: 'blur' },
    minMaxPass,
  ],
  newPasswordConfirm: [
    { validator: 'newPasswordConfirmValidator', trigger: 'blur' },
    minMaxPass,
  ],
}
