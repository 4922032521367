<template>
  <div class="assign-info-table">
    <el-table
      width="100%"
      :header-cell-style="{textAlign: 'center'}"
      :data="value"
    >
      <el-table-column type="index" width="44" />

      <el-table-column
        width="160px"
        align="center"
        :label="$t('tableLabels.assignDate')"
      >
        <template slot-scope="{ row }">
          {{ getDate(row.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column
        width="200px"
        align="center"
        :label="$t('tableLabels.testing_aim')"
      >
        <template slot-scope="{ row }">
          {{ aimsNames[row.testingAimId] }}
        </template>
      </el-table-column>

      <el-table-column
        min-width="64px"
        align="center"
        :label="$t('tableLabels.testName')"
      >
        <template slot-scope="{ row }">
          {{ row.test ? row.test.name : '-' }}
        </template>
      </el-table-column>

      <el-table-column
        width="102px"
        align="center"
        :label="$t('tableLabels.dueDate')"
      >
        <template slot-scope="{ row }">
          {{ getDate(row.dueDate) }}
        </template>
      </el-table-column>

      <el-table-column
        width="106px"
        align="center"
        :label="$t('tableLabels.started')"
      >
        <template slot-scope="{ row }">
          {{ getDate(row.startedAt) }}
        </template>
      </el-table-column>

      <el-table-column
        width="200px"
        align="center"
        :label="$t('tableLabels.testingDuration')"
      >
        <template slot-scope="{ row }">
          {{ formattedTime(row.testDuration) }}
        </template>
      </el-table-column>

      <el-table-column
        width="150px"
        :label="$t('tableLabels.status')"
      >
        <template slot-scope="{ row }">
          <div class="relative">
            <el-button
              plain
              :type="EVENT_STATUSES[row.status]"
              class="disable-event status-btn"
              @click="$emit('show-assignment', row)"
            >
              {{ assignmentStatuses[row.status] }}
            </el-button>

            <el-tooltip :content="$t('buttons.reassign')">
              <div v-if="isCanReAssign(row)" class="icon__wrapper">
                <i
                  class="icon el-icon-refresh-left enable-event"
                  @click="$emit('reassign', row)"
                />
              </div>
            </el-tooltip>

          </div>

        </template>
      </el-table-column>

      <el-table-column width="100px" :label="$t('tableLabels.actions')">
        <template slot-scope="{ row }">
          <el-button-group size="mini">
            <el-tooltip :content="$t('buttons.viewReport')">
              <el-button
                plain
                icon="el-icon-view"
                class="option"
                :disabled="!isDone(row)"
                @click="$emit('test:use-options', row)"
              />
            </el-tooltip>
            <el-tooltip :content="$t('buttons.sendReport')">
              <el-button
                plain
                icon="el-icon-message"
                class="option option--send-report"
                :disabled="!isDone(row)"
                @click="sendReport(row)"
              />
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>

      <el-table-column min-width="210px" :label="$t('tableLabels.notes')">
        <template slot-scope="{ row }">
          <TextEditInput
            class="w-100"
            :value="row.notes"
            @save="$emit('row:update', {
              formData: { notes: $event },
              originData: row
            })"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { AssignmentStatus, formattedTime, getDate } from '@/services'
import TextEditInput from '../../core/components/TextEditInput'

export default {
  name: 'AssignInfoTable',

  components: {
    TextEditInput,
  },

  props: {
    readonly: Boolean,
    value: { type: Array, default: () => [] },
  },

  data: () => ({
    key: 0,
    EVENT_STATUSES: {
      1: 'warning',
      2: 'danger',
      3: 'success',
    },
  }),

  watch: {
    value: {
      deep: true,
      handler() {
        this.key += 1
      },
    },
  },

  computed: {
    // TODO replace to global functions
    assignmentStatuses() {
      return Object
        .entries(AssignmentStatus)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [value]: this.$t(`assignmentStatus.${key.toLowerCase()}`),
        }), {})
    },

    aimsNames() {
      const list = this.$store.getters['testingAim/testingAims']
      return list.reduce((acc, aim) => ({ ...acc, [aim.id]: aim.name }), {})
    },
  },

  methods: {
    getDate,
    formattedTime,

    isDone({ status }) {
      return status === AssignmentStatus.COMPLETED
    },

    isCanReAssign({ status }) {
      return !this.readonly && status === AssignmentStatus.NOT_COMPLETED
    },

    sendReport(row) {
      this.$emit(
        'test:use-options',
        { ...row, isAction: 'test:send-report' },
      )
    },
  },
}
</script>

<style scoped lang="scss">
.assign-info-table {
  width: 100%;
  overflow: auto;

  .el-table /deep/ tr:hover > td {
    background-color: transparent;
  }

  .icon__wrapper {
    position: absolute;
    top: 6px;
    right: -10px;

    .icon {
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid $grey-800;
      background-color: $grey-200;
      padding: 2px;
      color: $grey-800;

      &:hover {
        box-shadow: $--box-shadow-base;
      }
    }
  }

  .status-btn {
    width: 125px;
    padding-right: 2px;
    padding-left: 2px;
    text-align: center;
  }
}
</style>
