<template>
  <div class="error-page text-center">
    <div class="mb-4 mt-4">
      <h1 class="color-red">
        {{ $t('routes.error') }}
        {{ e && e.request.status }}
      </h1>
    </div>

    <div class="mb-4 text-c-d">{{e && e.message}}</div>

    <router-link :to="{ name: 'home' }">
      {{ $t('buttons.backToHome') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',

  props: ['e'],
}
</script>
