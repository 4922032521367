<template>
  <el-table
    class="users-table"
    stripe
    border
    height="100%"
    width="100%"
    :data="value"
    @selection-change="$emit('selection-change', $event)"
  >
    <el-table-column type="index" :index="index" width="50" />

    <el-table-column type="selection" width="44" />

    <el-table-column
      sortable
      min-width="120"
      prop="lastName"
      :label="$t('tableLabels.lastName')"
      :sort-method="(a, b) => a.lastName.localeCompare(b.lastName)"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.lastName }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      prop="firstName"
      min-width="84"
      :label="$t('tableLabels.firstName')"
    >
      <template slot-scope="{ row }">
        <TextHighlight :queries="searchQuery">
          {{ row.firstName }}
        </TextHighlight>
      </template>
    </el-table-column>

    <el-table-column
      prop="position.name"
      min-width="180"
      :label="$t('tableLabels.position')"
     />

    <el-table-column
      prop="subdivision.fullName"
      min-width="180"
      :label="$t('tableLabels.subdivision')"
    />

    <el-table-column
      prop="current_event.name"
      width="180"
      :label="$t('tableLabels.currentEvent')"
    >
      <template slot-scope="{ row }">
        <el-button
          plain
          :type="EVENT_STATUSES[row.current_event.id]"
          :class="['event-btn',
            { 'disable-event': !canViewAssignments(row.current_event.id) }
          ]"
          @click="$emit('show-assignment', row)"
        >
          {{ row.current_event.name }}
        </el-button>
      </template>
    </el-table-column>

    <el-table-column
      prop="assignments"
      min-width="86"
      :label="$t('tableLabels.assignmentStatuses')"
    >
      <template slot-scope="{ row }">
        <el-tag
          v-for="item in row.assignments"
          :key="item.label"
          :color="getAssignmentStatusTag(item.status).color"
          :type="getAssignmentStatusTag(item.status).type"
          class="assignment-tag"
          effect="plain"
          size="mini"
        />
      </template>
    </el-table-column>

    <el-table-column
      prop="testing_aim.name"
      min-width="120"
      :label="$t('tableLabels.testingAim')"
    />

    <el-table-column
      prop="target_position.name"
      min-width="180"
      :label="$t('tableLabels.targetPosition')"
    />

    <el-table-column :label="$t('tableLabels.operations')" width="110px">
      <template slot-scope="{ row }">
        <el-button-group>
          <el-tooltip :content="$t('buttons.testingHistory')">
            <el-button
              icon="el-icon-time"
              @click="$router.push({
                name: 'user.edit',
                params: { id: row.id },
                query: { tab: 'history' }
              })"
            />
          </el-tooltip>

          <el-tooltip :content="$t('buttons.edit')">
            <el-button
              v-if="!row.deletedAt"
              plain
              type="primary"
              icon="el-icon-edit"
              @click="$router.push({
                name: 'user.edit',
                params: { id: row.id }
              })"
            />
          </el-tooltip>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import filteredTableMixin from '@/modules/core/mixins/filteredTableMixin'
import { AssignmentStatus, canViewAssignments } from '@/services'

const STATUS = {
  [AssignmentStatus.NOT_STARTED]: { color: '#faecd8', type: 'warning' },
  [AssignmentStatus.NOT_COMPLETED]: { color: '#fde2e2', type: 'danger' },
  [AssignmentStatus.COMPLETED]: { color: '#e1f3d8', type: 'success' },
}

export default {
  name: 'UsersTable',

  mixins: [filteredTableMixin],

  data: () => ({
    EVENT_STATUSES: {
      2: 'warning',
      3: 'danger',
      4: 'success',
    },
  }),

  methods: {
    canViewAssignments,
    getAssignmentStatusTag(status) {
      return STATUS[status] || {}
    },
  },
}
</script>

<style scoped lang="scss">
.users-table {
  .el-button-group {
    width: fit-content;
  }

  .assignment-tag {
     line-height: 0!important;
     height: 32px;
     margin: 2px 2px -5px 0;
  }

  .event-btn {
    width: 100%;
  }
}
</style>
