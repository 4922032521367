<template>
  <section class="login-page">
    <el-card class="login-card">
      <h2>{{ $t('form.name.login') }}</h2>

      <LoginForm @auth:success="onLogin" />
    </el-card>
  </section>
</template>

<script>
import LoginForm from './components/LoginForm'

export default {
  name: 'LoginPage',

  components: {
    LoginForm,
  },

  methods: {
    onLogin() {
      return this.$router
        .push(this.$route.query.redirect || '/home')
        .catch(() => this.$router.push({ name: 'home' }))
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100%;
  display: flex;
  width: 100%;

  .el-card {
    margin: auto;
    width: 400px;
    height: fit-content;
    text-align: center;
  }
}
</style>
