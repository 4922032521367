import { GET, POST } from '@/plugins/http'

export default {
  state: () => ({
    testsInfo: [],
  }),

  mutations: {
    setTestsInfo: (state, info) => { state.testsInfo = info },
  },

  actions: {
    async getAssignment(_, { id, query = {} }) {
      const params = {
        reportPackage: query.reportPackage || 'standard',
        detailing: query.detailing || 'base',
        language: query.language || 'uk',
      }

      const { data } = await GET(`/reports/assignment/${id}`, {
        params,
        responseType: 'arraybuffer',
      })

      return data
    },

    async fetchTestsInfo({ commit, getters }) {
      if (getters.testsInfo.length) {
        return getters.testsInfo
      }

      const { data } = await GET('/reports/info')
      commit('setTestsInfo', data)

      return data
    },

    sendReport(_, { id, options }) {
      return POST(`/reports/send/${id}`, options)
    },
  },

  getters: {
    testsInfo: state => state.testsInfo,
  },
}
