import Vue from 'vue'
import Vuex from 'vuex'
import { get, set } from 'idb-keyval'
import i18n from '../plugins/i18n'
import auth from './auth'
import admin from './admin'
import assignment from './assignment'
import dependency from './dependency'
import filters from './filters'
import subAdmin from './subAdmin'
import position from './position'
import currentEvent from './currentEvent'
import testingAim from './testingAim'
import subdivision from './subdivision'
import test from './test'
import user from './user'
import reports from './reports'

Vue.use(Vuex)

// SET MODULES WITH NAMESPACE HERE:
auth.namespaced = true
admin.namespaced = true
assignment.namespaced = true
dependency.namespaced = true
filters.namespaced = true
subAdmin.namespaced = true
position.namespaced = true
currentEvent.namespaced = true
testingAim.namespaced = true
subdivision.namespaced = true
test.namespaced = true
user.namespaced = true
reports.namespaced = true

const localeStoreKey = 'locale'

export default new Vuex.Store({
  state: () => ({
    locale: '',
    isAppLoaded: false,
    router: null,
  }),

  mutations: {
    setLocale: (state, payload) => { state.locale = payload },
    setIsAppLoaded: (state, payload) => { state.isAppLoaded = payload },
    setRouter: (state, router) => { state.router = router },
  },

  actions: {
    async setDefaultLocale({ dispatch }) {
      const locale = await get(localeStoreKey) || i18n.locale
      return dispatch('setLocale', { locale })
    },

    setLocale({ commit }, { locale }) {
      i18n.locale = locale
      set(localeStoreKey, locale)
      commit('setLocale', locale)
      return locale
    },
    setIsAppLoaded({ commit }, isAppLoaded) {
      window.isAppLoaded = isAppLoaded
      commit('setIsAppLoaded', isAppLoaded)
      return isAppLoaded
    },
    setRouter({ commit }, router) {
      commit('setRouter', router)
    },
  },

  getters: {
    locale: state => state.locale,
    isAppLoaded: state => state.isAppLoaded,
    router: state => state.router,
  },

  modules: {
    auth,
    admin,
    assignment,
    dependency,
    filters,
    subAdmin,
    position,
    currentEvent,
    testingAim,
    subdivision,
    test,
    user,
    reports,
  },
})
